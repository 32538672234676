<template>
    <div class="dashboard">
        <b-row style="width:50%; float: right; margin-top: -50px; margin-bottom: 40px;">
            <b-col class="text-right"> 
                
                    <button class="btn btn-primary" @click="$router.push({ path: '/formacao/cursos-agendados/novo', params: {} })">  Adicionar Ação de Formação</button>
                
            </b-col>
        </b-row>


        <b-row class="col-12 mt-3">
            <b-col>
                <p>Ações de Formação Agendadas</p>
            </b-col>
        </b-row>

        <b-row class="col-12 mt-3">
            <b-col>
                <datatable  class=" m-0 p-0" 
                            :searchable="true"
                            :rows="data"
                            :columns="[{label: 'Entidade', field: 'entity', sortable:false}, {label:'Curso', field:'course', sortable:false}, {label:'Inicio', field:'start_date'}, {label:'Fim', field:'end_date'}, {label: 'Horas Certificadas', field:'no_hours', sortable:false}, {label: 'Horas Agendadas', field: 'no_hours', sortable:false}, {label:'Nº Inscritos', field:'no_attendants', sortable:false}]"
                            @rowclick="rowclick_callback" 
                            :initialsort = "[{field: 'start_date', type: 'asc'}]"
                            
                >

                </datatable>
            </b-col>
        </b-row>
        

        <!-- <b-row class="col-12 mt-3">
            <b-col>
                <pre>{{data}}</pre>
            </b-col>
        </b-row> -->
    </div>
</template>
    
<script>
    export default {
    name: 'List',
    components: {},
    data() {
        return {
            data:{}
        };
    },
    computed: {

    },
    methods: {
        rowclick_callback(value){
            //console.log(value);
            // let self = this;
            if(value.action == 'link') this.$router.push({ path: '/formacao/cursos-agendados/'+value.row.id, params: {} });
        },
    },
    
    beforeMount() {
    },
    mounted() {   
    },
    created() {
        let self = this; 


        self.api.get('tad/courses/list_actions').then((r)=>{
            if(r.success){
                console.log(r);
                self.data = r.dados;
            }
        });
    }
}
</script>
    