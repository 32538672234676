<template>
    <div class="cp">
        <b-row style="width:50%; float: right; margin-top: -50px; margin-bottom: 40px;">
            <b-col class="text-right"> 
                
                    <button class="btn btn-primary" @click="$router.push({ path: '/formacao/cursos-agendados/'+id+'/edit', params: {} })">  Editar Ação </button>
                    <button class="btn btn-danger ml-3" @click="eliminar()">  <i class="fa fa-trash"></i> </button>
                
            </b-col>
        </b-row>
        <b-row  style="width:100%;">
            <b-col xs="12" sm="12" md="8" lg="9">
                <!--  <pre>{{data}}</pre> -->                
                <Widget>

                    <div class="col-12 p-0 row">
                        <label class="col-4" for="mec_number">Entidade</label>
                        <div class="col-8">
                            {{data.entity}}
                        </div>
                    </div>
    
                    <div class="col-12 p-0 row">
                        <label class="col-4" for="">Curso</label>
                        <div class="col-8">
                            {{data.course}}
                        </div>
                    </div>
                    
                    <div class="col-12 p-0 row">
                        <label class="col-4" for="mec_number">Tipologia</label>
                        <div class="col-8">
                            {{data.type}}
                        </div>
                    </div>

                    <div class="my-5"></div>
                    
                    <div class="col-12 p-0 row" v-if="data.address">
                        <label class="col-4" for="mec_number">Localização sessões presenciais</label>
                        <div class="col-8">
                            {{data.address}}
                        </div>
                    </div>
                    <div class="col-12 p-0 row" v-if="data.platform">
                        <label class="col-4" for="mec_number">Plataforma sessões eLearning</label>
                        <div class="col-8">
                            {{data.platform}}
                        </div>
                    </div>

                    <div class="my-5"></div>
                    
                    
                    <div class="col-12 p-0 row">
                        <label class="col-4" for="mec_number">Categoria </label>
                        <div class="col-8">
                            {{data.category}}
                        </div>
                    </div>

                    <div class="col-12 p-0 row">
                        <label class="col-4" for="mec_number">Objetivos</label>
                        <div class="col-8">
                            {{data.objectives}}
                        </div>
                    </div>
    
                </Widget>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="3">
                <Widget style="font-size: 12px; padding: 20px 0px;">
                    <label class="col-4" for="">Início</label> <span class="col-8">{{data.start_date}}</span>
                    <label class="col-4" for="">Fim</label> <span class="col-8">{{data.end_date}}</span>
                    <div class="spacer my-3"></div>
                    <label class="col-7" for="">Horas Cert.</label> <span class="col-5">{{data.no_hours}}</span>

                </Widget>

                <Widget style="font-size: 12px; padding: 20px 0px;">
                    <label class="col-7" for="">Mín. Participantes</label> <span class="col-5">{{data.min_participants}}</span>
                    <label class="col-7" for="">Max. Participantes</label> <span class="col-5">{{data.max_participants}}</span>
                    <div class="spacer my-3"></div>
                    <label class="col-7" for="">Nº Inscritos</label> <span class="col-5">{{data.no_attendants}}</span>
                </Widget>
            </b-col>
        </b-row>


        <b-row>
            <b-col>
                <Widget>
                    <strong>Brevemente :</strong> Agendamento de Sessões || Lista de inscritos
                </Widget>
            </b-col>
        </b-row>
        
    </div>
</template>
    
<script>
    export default {
    name: 'Single',
    components: {},
    data() {
        return {
            data: {}
        };
    },
    computed: {
        id(){
            let id = this.$route.params.id || null;
            return id;
            // return Number.isInteger(id) ? id : null
        }
    },
    methods: {
        eliminar(){
            let self = this;
            

            
            this.swal.fire({
                title: 'De certeza que pretende  esta Ação de Formação ?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Eliminar`,
                denyButtonText: `Cancelar`,
                }).then((result) => {
                if (result.isConfirmed) {
                    self.api.delete('tad/courses/delete_action/'+this.id).then(function(r){
                        if(r.success){ self.$router.push('/formacao/cursos-agendados');
                        }else{
                            // self.$router.push('/empresa/recursos-humanos/');
                        }
                    });


                } else if (result.isDenied) {
                    //do Nothing.
                }
                })

        
        }
    },
    
    beforeMount() {
    },
    mounted() {   
    },
    created() {
        let self = this;
        if(this.$route.params.id) {
            self.api.get('tad/courses/get_action/'+this.id).then((r)=>{
                console.log(r);
                self.$set(self, 'data', r.dados);
            });
        }else{
            self.$router.push('/formacao/cursos-agendados');
        }
    }
}
</script>
    