<template>
    <div class="componente">
        <transition name="router-animation">
            <router-view :options="options" :courseslist="courseslist" :entitieslist="entitieslist" @updatecourses="load_courses" @updateentities="load_entities"/>
        </transition>
    </div>
</template>
    
<script>
    export default {
    name: 'Componente',
    components: {},
    data() {
        return {
            options: {},
            courseslist: [],
            entitieslist: [],
            r: {}
        };
    },
    computed: {
    },
    methods: {
        load_data(){
            this.load_options();
            // this.load_courses();
            // this.load_entities();
        },
        load_options(){
            let self = this;
            
            self.api.post('tad/load_options', {}).then((r)=>{
                console.log(r);
                if(r.success){
                    self.$set(self.options, 'loaded', true);
                    self.$set(self.options, 'course_categories', r.dados.course_categories);
                    self.$set(self.options, 'course_types', r.dados.course_types);
                    self.$set(self, 'courseslist', r.dados.courses);  
                    self.$set(self, 'entitieslist', r.dados.entities);     
                }
            });
        },
        load_courses(){
            let self = this;
            
            self.api.get('tad/courses/list', {}).then((r)=>{
                console.log(r);
                if(r.success){
                    self.$set(self, 'courseslist', r.dados);    
                }
            });
        },
        load_entities(){
            let self = this;
            self.api.post('tad/entities/list', {}).then((r)=>{
                console.log(r);
                if(r.success){
                    self.$set(self, 'entitieslist', r.dados);    
                }
            });
        },
    },
    beforeMount() {
    },
    mounted() {   
        this.load_data();
    },
    created() {

    }
}
</script>
    