import Dashboard from '@/pages/Company/Dashboard.vue';

export default [
    {
        path: 'empresa/',
        name: 'Empresa',
        component: Dashboard,
        meta: {
            requiresAuth: true
        }
    }
]