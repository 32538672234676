var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-wrapper"},[_c('nav',{class:{sidebar: true, sidebarStatic: _vm.sidebarStatic, sidebarOpened: _vm.sidebarOpened},on:{"mouseenter":_vm.sidebarMouseEnter,"mouseleave":_vm.sidebarMouseLeave}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"}},[_c('header',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/dashboard"}},[(!_vm.sidebarOpened && !_vm.sidebarStatic)?_c('span',{staticClass:"primary-word"},[(_vm.appConfig.options && _vm.appConfig.options.brand_icon)?_c('img',{staticStyle:{"height":"45px"},attrs:{"src":_vm.appConfig.options.base_url+_vm.appConfig.options.brand_icon,"alt":""}}):_c('img',{staticStyle:{"height":"45px"},attrs:{"src":require("../../assets/imagens/icon.png"),"alt":""}})]):_c('span',{staticClass:"primary-word"},[(_vm.appConfig.options && _vm.appConfig.options.brand_logo)?_c('img',{staticStyle:{"height":"45px"},attrs:{"src":_vm.appConfig.options.base_url+_vm.appConfig.options.brand_logo,"alt":""}}):_c('img',{staticStyle:{"height":"45px"},attrs:{"src":require("../../assets/imagens/icon.png"),"alt":""}})])])],1),_c('ul',{staticClass:"nav"},[_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Dashboard","link":"/dashboard","iconName":"flaticon-home","index":"dashboard","isHeader":""}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Empresa","link":"/empresa","iconName":"flaticon-switch-2","index":"empresa","isheader":"","childrenLinks":[
            { header: 'Departamentos', link: '/empresa/departamentos' },
            { header: 'Recursos Humanos', link: '/empresa/recursos-humanos' }
          ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Formação","link":"/formacao","iconName":"flaticon-share-2","index":"formacao","isheader":"","childrenLinks":[
            { header: 'Cursos Agendados', link: '/formacao/cursos-agendados' }              
          ]}})],1),_c('div',{staticClass:"mt-auto",attrs:{"id":"bottom-menu"}},[_c('ul',{staticClass:"nav"},[_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"User","link":"/user","iconName":"flaticon-user","index":"user","isHeader":"","childrenLinks":[
          { header: 'Meu Perfil', link: '/user' } ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Config","link":"/config","iconName":"flaticon-app","index":"config","childrenLinks":[
            { header: 'Configurar Sistema', link: '/config' } ]}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }