<template>
    <div class="dashboard">
        <b-row style="width:50%; float: right; margin-top: -50px; margin-bottom: 40px;">
            <b-col class="text-right"> 
                
                    <button class="btn btn-primary" @click="$router.push({ path: '/formacao/cursos-agendados/novo', params: {} })">  Adicionar Ação de Formação</button>
                
            </b-col>
        </b-row>
    </div>
</template>
    
<script>
    export default {
    name: 'List',
    components: {},
    data() {
        return {
        };
    },
    computed: {

    },
    methods: {

    },
    
    beforeMount() {
    },
    mounted() {   
    },
    created() {

    }
}
</script>
    