<template>
    <div class="componente">
        
    
        <router-view />

    </div>
    </template>
    
<script>
    export default {
    name: 'Departments',
    components: {},
    data() {
        return {
            action: null,
            id: null
        };
    },
    computed: {

    },
    methods: {

    },
    
    beforeMount() {
        
    },
    mounted() {   
    },
    created() {
        if(this.$route.params.action) {this.action = this.$route.params.action;}
        if(this.$route.params.id) {this.id = this.$route.params.id;}
    }
}
</script>
    