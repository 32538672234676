import config from '../config';

export default {
    data: () => {
        return {
            appConfig: config.app,
        }
    },
    watch:{
      appConfig: {
        handler(){
          
        }, deep:true
      }
    },
    beforeMount() {
      
    },
    created() {
      
    },
    mounted() {
      
      var self = this;

      if(self.appConfig.options && self.appConfig.options.sitename){
        //CHANGE DOCUMENT TITLE
        document.title = self.appConfig.options.sitename;
      }
      if(self.appConfig.options && self.appConfig.options.brand_icon){
        //CHANGE DOCUMENT FAVICON
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = self.appConfig.options.base_url+self.appConfig.options.brand_icon;
      }
      

      
    },
  methods: {
    decodeHtml(html) {
      let txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }
  }
};
