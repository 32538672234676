<template>
    <div class="dashboard-page">
      <b-row>
        <b-col lg="6" sm="6" xs="12">
          <div class="pb-xlg h-100">
            <Widget class="h-100 mb-0" xtitle="Visits Today" xclose>
              <!-- <div class="d-flex justify-content-between align-items-center mb-lg">
                <h2>4,332</h2>
                <i class="la la-arrow-right text-primary la-lg rotate-315" />
              </div>
              <div class="d-flex flex-wrap justify-content-between">
                <div class="mt">
                  <h6>+830</h6><p class="text-muted mb-0 mr"><small>Logins</small></p>
                </div>
                <div class="mt">
                  <h6>0.5%</h6><p class="text-muted mb-0"><small>Sign Out</small></p>
                </div>
                <div class="mt">
                  <h6>4.5%</h6><p class="text-muted mb-0 mr"><small>Rate</small></p>
                </div>
              </div> -->
            </Widget>
          </div>
        </b-col>      
  
        <b-col lg="6" sm="6" xs="12">
          <div class="pb-xlg h-100">
            <Widget class="h-100 mb-0" xtitle="App Perfomance" xclose>
              <!-- <p class="text-muted d-flex flex-wrap">
                <small class="mr-lg d-flex align-items-center">
                  <span class="circle bg-danger text-danger mr-xs" style="font-size: 4px;">
                    .
                  </span>
                    This Period
                </small>
                <small class="mr-lg d-flex align-items-center">
                  <span class="circle bg-primary text-primary mr-xs" style="font-size: 4px;">
                    .
                  </span>
                  Last Period
                </small>
              </p>
              <h6>SDK</h6>
              <b-progress class="mb-xs" style="height: 5px"
                variant="danger" :value="60" :max="100" />
              <b-progress class="mb" style="height: 5px"
                variant="primary" :value="35" :max="100" />
              <h6>Integration</h6>
              <b-progress class="mb-xs" style="height: 5px"
                variant="danger" :value="40" :max="100" />
              <b-progress style="height: 5px"
                variant="primary" :value="55" :max="100" /> -->
            </Widget>
          </div>
        </b-col>
        
      </b-row>
  <!--  
      <b-row>
        <b-col xs="12">
          <Widget
            title="<h5>Dados Autenticação</h5>"
            bodyClass="widget-table-overflow"
            customHeader
          >
          <pre>
            {{user}}
          </pre>
          </Widget>
        </b-col>
      </b-row> -->
  
      <!--
      <b-row>
        <b-col xs="12">
          <Widget
            title="<h5>Dashboard <span class='fw-semi-bold'>Travel Gate</span></h5>"
            bodyClass="widget-table-overflow"
            customHeader
          >
            {{teste}}
          </Widget>
        </b-col>
      </b-row>
      -->
      
      <pre>
        {{user}}
      </pre>
      
      <pre>
        {{permissions}}
      </pre>
  
      <!-- <p>Vue State :: USER </p>
      <pre>
        {{user_status}}
      </pre> -->
  
      <p>Vue Prototipe :: isAdmin()  </p>
      <pre>{{prototypeIsAdmin}}</pre>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import Widget from '@/components/Widget/Widget';
  
  //import BigStat from './components/BigStat/BigStat';
  //import mock from './mock';
  //import { Chart } from 'highcharts-vue';
  
  export default {
    name: 'UserProfile',
    components: {
      Widget 
      //BigStat
      //highcharts: Chart
    },
    data() {
      let user = window.jsonxDec(window.localStorage.getItem('user'));
      return {
          teste: 'aaa',
          user: user
      };
    },
    computed:{
      ...mapState('user', ['permissions']),
  
      user_status() {
        return this.$store.state.user;
      },
  
      prototypeIsAdmin() {
        return this.$isAdmin(); 
      }
    },
    created() {
      let self = this;
      this.api.post('App/Teste/teste', {nome:"teste com sucesso"}).then(function(data){
        console.log(data);
        self.teste = data.dados;
        self.$forceUpdate();
      });
  
      // this.permissions = this.user.permissions;
  
      this.$forceUpdate();
  
    },
    methods: {
      getRandomData() {
        const arr = [];
  
        for (let i = 0; i < 25; i += 1) {
          arr.push(Math.random().toFixed(1) * 10);
        }
  
        return arr;
      },
      getRevenueData() {
        const data = [];
        const seriesCount = 3;
        const accessories = ['SMX', 'Direct', 'Networks'];
  
        for (let i = 0; i < seriesCount; i += 1) {
          data.push({
            label: accessories[i],
            data: Math.floor(Math.random() * 100) + 1,
          });
        }
  
        return data;
      }
    },
    
  };
  </script>
  
  <style lang="scss"></style>
  