<template>
    <div class="col-12 row">
                <form class="col-12 row m-0 p-0" @submit.prevent="save">
    
                    <p class="col-12" style="font-size: 20px">
                        Registo terminado com sucesso. <br>
                        
                        
                            <button @click="login" class="btn border px-5 py-2 mx-5 mt-5">Iniciar Sessão</button>
                        
                    </p>
    
                </form>
    </div>
    </template>
    
    <script>
    export default {
    name: 'step01',
    props: ['info', 'account'],
    components: {},
    data() {
        return {
            loading: false
        };
    },
    computed: {
    },
    methods: {
        login(){
            this.$router.push('/login');
        }
    },
    created() {
    },
    mounted() {    
    }
    };
    </script>
    