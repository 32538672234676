<template>
    <div class="col-12 row">
        Form {{id ? 'editar ' + id : 'novo'}}
        <div class="d-flex align-items-center justify-content-center col-12 row" v-if="loading" style="height: 80vh; width:100%;">
            <div class="col-12 text-center">
                <i class="fa fa-spin fa-spinner fa-4x"></i>
            </div>
        </div>
        <form class="col-12" @submit.prevent="save" v-if="!loading">


            <div class="formgroup col-12 my-5 text-center">
                <button class="btn btn-save border px-5" type="submit" v-if="!saving">Guardar</button>
                <span class="btn btn-save px-5 border" v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
            </div>
        </form>
    </div>
</template>
    
<script>
    export default {
    name: 'Form',
    components: {},
    data() {
        return {
            saving: false,
            loading: false,
            data: {}
        };
    },
    computed: {
        id(){
            let id = this.$route.params.id || null;
            return id;
            // return Number.isInteger(id) ? id : null
        }
    },
    watch:{
        id(val){
            let self = this;
            self.$set(this, 'loading', true);
            alert('id = ' + val);
            setTimeout(function (){
                self.$set(self, 'loading', false);
            }, 5000); 
        }
    },
    methods: {
        save(){
            let self = this;
            self.saving = true;
            console.log(self.data);
            setTimeout(function (){
                self.saving = false;
            }, 5000); 
            
        }
    },
    beforeMount() {
        
    },
    mounted() {   
        // if(this.$route.params.id) {this.id = this.$route.params.id;}
    },
    created() {
        
    }
}
</script>
    