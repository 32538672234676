<template lang="">
    <div class="col-12 row p-0 m-0">
        <!-- <select class="col-12" v-model="data.course_id" id="">
            <option value="">--</option>
            <option value="1">TESTE</option>
        </select> -->
        
        <multiselect v-model="data.course" 
                    :options="courses" 
                    
                    :multiple="false" 
                    :taggable="true" 
                    :searchable="true"
                    :close-on-select="true"

                    v-if="!showFrm"

                    label="title"
                    track-by="title"
                    placeholder="Seleccione um Curso"  
                    
                    tag-placeholder="Adicionar novo curso" 
                    selectLabel="Selecionar curso"
                    noOptions ="Sem resultados"
                    @tag="addCourse"
        >
                    <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.title }}</strong> </template>
        </multiselect>

        <div class="card col-12 row" v-if="showFrm">
            <div class="card-header">Adicionar Curso <i class="fa fa-times" @click="reset"></i></div>
                FORM novo CURSO
                {{course}}
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect'

    export default {
    name: 'SearchCourse',
    props: ['data', 'options', 'courses', 'entities'],
    components: {Multiselect},
    data() {
        return {
            id: null,
            showFrm: false,
            course: {}
        };
    },
    computed: {

    },
    methods: {
        addCourse(querystring){
            // this.showFrm = true;
            // this.$set(this.course, 'title', querystring);
            this.$router.push({name: 'NewCourse', params: {title: querystring}});
        },
        reset(){
            this.showFrm = false;
            this.$set(this, 'course', {});

        }
    
    },
    
    beforeMount() {
        
    },
    mounted() {   
    },
    created() {
    
    }
}
</script>
    