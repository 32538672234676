import Vue from 'vue';
import Router from 'vue-router';
// import store from '@/store/index.js'


import Layout from '@/components/Layout/Layout';
import Install from '@/pages/Install/Install';
import Login from '@/pages/Login/Login';
import Registo from '@/pages/Login/Registo';
import ErrorPage from '@/pages/Error/Error';


//COMPONENTES COM ROUTES
    import Sample_Component_Routes from '@/pages/_Component/_Routes';
    
    import Company_Routes from '@/pages/Company/_Routes'
    import HR_Routes from '@/pages/Company/HR/_Routes';
    import Departments_Routes from '@/pages/Company/Departments/_Routes';
    import TAD_Routes from '@/pages/TAD/_Routes';


//PAGINAS    
    import Dashboard from '@/pages/Dashboard/Dashboard';
    import UserProfile from '@/pages/User/Profile';
    
    
    import Config from '@/pages/Config/Config';


//TEMP
    // Core
    import TypographyPage from '@/pages/Template/Typography/Typography';
    // Tables
    import TablesBasicPage from '@/pages/Template/Tables/Basic';
    // Maps
    import GoogleMapPage from '@/pages/Template/Maps/Google';
    // Main
    import DashboardOriginal from '@/pages/Template/Dashboard/DashboardOriginal';
    // Charts
    import ChartsPage from '@/pages/Template/Charts/Charts';
    // Ui
    import IconsPage from '@/pages/Template/Icons/Icons';
    import NotificationsPage from '@/pages/Template/Notifications/Notifications';


Vue.use(Router);

const routes = [
  {
    path: '/install',
    name: 'Install',
    component: Install,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/registo/corporate/:hash',
    name: 'RegistoCorporate',
    component: Registo,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/registo',
    name: 'Registo',
    component: Registo,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/recuperarsenha',
    name: 'Recuperar Senha',
    component: Registo,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage,
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true
        },
      },
      
      
      ...Sample_Component_Routes,
      ...Company_Routes, 
      ...Departments_Routes,
      ...HR_Routes,
      ...TAD_Routes,
      


      {
        path: 'user',
        name: 'Perfil',
        component: UserProfile,
        meta: {
          requiresAuth: true
        },
      },


      {
        path: 'config',
        name: 'Config',
        component: Config,
        meta: {
          requiresAuth: true
        },
      },


      //TEMP
      {
        path: 'dashboard-original',
        name: 'AnalyticsPage',
        component: DashboardOriginal,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'typography',
        name: 'TypographyPage',
        component: TypographyPage,
      },
      {
        path: 'components/icons',
        name: 'IconsPage',
        component: IconsPage,
      },
      {
        path: 'notifications',
        name: 'NotificationsPage',
        component: NotificationsPage,
      },
      {
        path: 'components/charts',
        name: 'ChartsPage',
        component: ChartsPage,
      },
      {
        path: 'tables',
        name: 'TablesBasicPage',
        component: TablesBasicPage,
      },
      {
        path: 'components/maps',
        name: 'GoogleMapPage',
        component: GoogleMapPage,
      },
    ],
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // console.log('de')
  // console.log(from)
  // console.log('para')
  // console.log(to)
  
  
  if(to.path === '/' || to.path === ''){
    next({
      path: '/dashboard',
      params: { nextUrl: to.fullPath }
    })
  }else{ 
    if (!to.matched.some(record => record.path)) {
      next({
        path: '/error',
        params: { nextUrl: to.fullPath }
      })
    }
  }

  //Valida se temos configuração carregada
  
  
  //Valida se route requer autenticação
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (window.localStorage.getItem('authenticated') == false) {
      // store.state.jwt = null
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      
      let user = {}
      if (window.localStorage.getItem('user') === null) {
        // alert('vamos ao login')
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        })  
      }else{
        // console.log(window.localStorage.getItem('user'))
      }
      
      if (to.matched.some(record => record.meta.require_admin)) {
        if (user.require_admin) {
          next()
        } else {
          next()
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (window.localStorage.getItem('jwt') == null) {
      next()
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
