import CP from '@/pages/Company/HR/Employees';
import View from '@/pages/Company/HR/views/Employee';
import Form from '@/pages/Company/HR/views/EmployeeForm';
import List from '@/pages/Company/HR/views/EmployeesList';

export default [
  {
    path: 'empresa/recursos-humanos',
    alias: ['rh', 'hr'],
    name: 'RecursosHumanos',
    component: CP,
    meta: {
      requiresAuth: true
    },
    children:[
      {
        path: '',
        name: 'Employees',
        component: List,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'novo',
        name: 'EmployeeForm',
        component: Form,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: ':id',
        name: 'Employee',
        component: View,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: ':id/edit',
        name: 'EmployeeForm',
        component: Form,
        meta: {
          requiresAuth: true
        },
      },
      
    ]
  },
]