<template>
    <div class="tab-pane" :class="{ 'in active': isActive }" v-show="isActive"><slot></slot></div>
</template>

<script>
export default {
    name: 'Tabs',
    props: {
        name: { required: true },
        title: {default: false},
        selected: { default: false}
    },
    
    data() {
        
        return {
            isActive: false
        };
        
    },
    
    computed: {
        
        href() {
            return '#' + this.name.toLowerCase().replace(/ /g, '-');
        }
    },
    
    mounted() {
        
        this.isActive = this.selected;
        
    }

};
</script>