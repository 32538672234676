<template>
    <div class="col-12 row m-0 p-0">
        <!-- Form {{id ? 'editar ' + id : 'novo'}} -->
        <div class="d-flex align-items-center justify-content-center col-12 row" v-if="loading" style="height: 80vh; width:100%;">
            <div class="col-12 text-center">
                <i class="fa fa-spin fa-spinner fa-4x"></i>
            </div>
        </div>

        <form class="col-12 row m-0 p-0 pt-5" @submit.prevent="save" v-if="!loading">

            <!-- <pre class="col-12">{{data}}</pre> -->
        <Widget>
            <div class="col-12 text-center"  v-if="!options.loaded">
                <i class="fa fa-5x my-5 py-5 fa-spin fa-spinner"></i>
            </div>

            <div :class="data.course_id ? 'col-12' : 'col-12'" v-if="options.loaded">
                
                    <div class="formgroup col-12 row ">
                        <label class="col-4" for="">Curso</label>
                        <div class="col-8 row m-0 p-0">
                            <search-course v-if="options.loaded" :data="data" :options="options" :courses="courseslist" :entities="entitieslist"></search-course>
                            <p class="error text-danger" v-if="frmerror.course_id"> Campo obrigatório</p>
                        </div>
                    </div>

                    <div class="col-12 row m-0 p-0" v-if="data.course_id">
                        <!--SESSION DETAILS-->
                        <div class="formgroup col-12 row">
                            <label class="col-4" for="">Horário</label>
                            <div class="col-8 row m-0 p-0">
                                <select class="col-12" v-model="data.schedule" id="" required>
                                    <option value="">--</option>
                                    <option value="1">Laboral</option>
                                    <option value="1">Pós-Laboral</option>
                                </select>
                                <p class="error text-danger" v-if="frmerror.schedule"> Campo obrigatório</p>
                            </div>
                        </div>

                        <div class="formgroup col-12 row">
                            <label class="col-4" for="">Datas</label>
                            <div class="col-8 row m-0 p-0">
                                <div class="col-6 d-flex">
                                    <label for="" style="margin-top: 8px; margin-right: 8px;">De</label>
                                    <input class="col" type="date" v-model="data.start_date" :min="moment().format('yyyy-mm-dd')" :max="data.end_date" id="" required>
                                </div>
                                <div class="col-6 d-flex">
                                    <label for="" style="margin-top: 8px; margin-right: 8px;">a</label>
                                    <input class="col" type="date" v-model="data.end_date" :min="data.start_date" id="" required>
                                </div>
                                <p class="error text-danger" v-if="frmerror.course_id"> Campo obrigatório</p>
                            </div>
                        </div>
                        
                        
                        <div class="formgroup col-12 row">
                            <label class="col-4" for="">Horas Certificadas</label>
                            <div class="col-8 row m-0 p-0">
                                <input class="col-3" type="number" v-model="data.no_hours" id="" required>
                                <p class="col-12 error text-danger" v-if="frmerror.no_hours"> Campo obrigatório</p>
                            </div>
                        </div>

                        <div class="formgroup col-12 row">
                            <label class="col-4" for="">Mínimo Participantes</label>
                            <div class="col-8 row m-0 p-0">
                                <input class="col-3" type="number" v-model="data.min_participants" id="" >
                                <p class="col-12 error text-danger" v-if="frmerror.min_participants"> Campo obrigatório</p>
                            </div>
                        </div>
                        <div class="formgroup col-12 row">
                            <label class="col-4" for="">Máximo Participantes</label>
                            <div class="col-8 row m-0 p-0">
                                <input class="col-3" type="number" v-model="data.max_participants" id="" >
                                <p class="col-12 error text-danger" v-if="frmerror.max_participants"> Campo obrigatório</p>
                            </div>
                        </div>

                        <div class="formgroup col-12 row">
                            <label class="col-4" for="">Regime</label>
                            <div class="col-8 row m-0 p-0">
                                <label class="col text-center" for="">
                                    <input type="radio" v-model="data.type" value="Presencial"> Presencial
                                </label>
                                <label class="col text-center" for="">
                                    <input type="radio" v-model="data.type" value="B-Learning"> B-Learning (Misto) 
                                </label>
                                <label class="col text-center" for="">
                                    <input type="radio" v-model="data.type" value="E-Learning"> E-Learning
                                </label>
                                <p class="error text-danger" v-if="frmerror.course_id"> Campo obrigatório</p>
                            </div>
                        </div>


                        <div class="formgroup col-12 row" v-if="is_presential">
                            <div class="formgroup col-12 row ">
                                <label class="col-4" for="">Local Formação</label>
                                <div class="col-8 row m-0 p-0">
                                    <input type="text" class="col-12" v-model="data.address" >
                                    <p class="error text-danger" v-if="frmerror.address"> Campo obrigatório</p>
                                </div>
                            </div>
                        </div>

                        <div class="formgroup col-12 row" v-if="is_remote">
                            <div class="formgroup col-12 row ">
                                <label class="col-4" for="">Plataforma ensino à Distância</label>
                                <div class="col-8 row m-0 p-0">
                                    <input type="text" class="col-12" v-model="data.platform" >
                                    <p class="error text-danger" v-if="frmerror.platform"> Campo obrigatório</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 my-3 pt-5 ">
                            <div class="formgroup col-12 text-center">
                                <button class="btn btn-save border px-5" type="submit" v-if="!saving">Guardar</button>
                                <span class="btn btn-save px-5 border" v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
                            </div>
                        </div>

                    </div>
                    <!--  
                    <div class="formgroup col-12 row">
                        {{data}}
                    </div> 
                    -->
            </div>

            <!-- 
            <div class="col-4" v-if="data.course_id">
                <Widget>

                </Widget>
            </div> 
            -->
        
        </Widget>
        </form>
    </div>
</template>
    
<script>
    import SearchCourse from '../partials/search_course';

    export default {
    name: 'Form',
    components: {SearchCourse},
    props: ['options', 'courseslist', 'entitieslist'],
    data() {
        return {
            frmerror: {},
            saving: false,
            loading: false,
            
            data: {}
        };
    },
    computed: {
        is_remote(){
            return this.data.type == 'E-Learning' || this.data.type == 'B-Learning'  ? true : false;
        },
        is_presential(){
            return this.data.type == 'Presencial' || this.data.type == 'B-Learning'  ? true : false;
        },
        id(){
            let id = this.$route.params.id || null;
            return id;
            // return Number.isInteger(id) ? id : null
        }
    },
    watch:{
        id(){
            let self = this;
            self.$set(this, 'loading', true);
            // alert('id = ' + val);
            setTimeout(function (){
                self.$set(self, 'loading', false);
            }, 5000); 
        },
        'data.course'(){
            if(this.data.course.id) this.$set(this.data, 'course_id', this.data.course.id);
        },
        'data.course_id'(){
            
            const found = this.courseslist.find(element => element.id == this.data.course_id);
            if( !this.data.course.id || this.data.course.id != this.data.course_id){
                this.$set(this.data,'course', found);
            }
        },  
        'courseslist'(){
            if(this.data.course_id){
                const found = this.courseslist.find(element => element.id = this.data.course_id);
                if(!this.data.course || !this.data.course.id || this.data.course.id != this.data.course_id){
                    this.$set(this.data,'course', found);
                }
            }
        }   
    },
    methods: {
        save(){
            let self = this;
            self.saving = true;
            // console.log(self.data);
            // setTimeout(function (){
            //     self.saving = false;
            // }, 5000); 

            self.api.post('tad/courses/save_action', self.data).then( (r)=>{
                if(r.success){
                    self.saving = false;
                    this.$router.push('/formacao/cursos-agendados/'+r.dados);
                }else{
                    self.frmerror = r.error;
                }
            });
            
        }
    },
    beforeMount() {
        
    },
    mounted() {   
        // if(this.$route.params.id) {this.id = this.$route.params.id;}
        let self = this;
        if(this.$route.params.id) {
            self.api.get('tad/courses/get_action/'+this.id).then((r)=>{
                console.log(r);
                self.$set(self, 'data', r.dados);
                if(self.data.course) self.data.course = {};
            });
        }
    },
    created() {
        
    }
}
</script>
    