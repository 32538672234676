<template>
    <div class="dashboard">
        
    </div>
</template>
    
<script>
    export default {
    name: 'Dashboard',
    components: {},
    data() {
        return {
        };
    },
    computed: {

    },
    methods: {

    },
    
    beforeMount() {
        console.log(this.$router);
        this.$router.push('/empresa/departamentos');
    },
    mounted() {   
    },
    created() {
        
    }
}
</script>
    