import Component from './Component';
import List from './views/list';
import Form from './views/form';
import Single from './views/single';

export default [
  {
    path: 'componente',
    alias: ['cp', 'cpn'],
    name: 'Componente',
    component: Component,
    meta: {
      requiresAuth: true
    },
    children:[
      {
        path: '',
        name: 'List',
        component: List,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'novo',
        name: 'New',
        component: Form,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: ':id',
        name: 'View',
        component: Single,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: ':id/edit',
        name: 'Edit',
        component: Form,
        meta: {
          requiresAuth: true
        },
      }
      
    ]
  },
]