<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  data() {
    return{
      appConfig: {},
    }
  },
  watch:{
    
  },
  beforeMount() {
     
  },
  created() {
    const currentPath = this.$router.history.current.path;
    // if (window.localStorage.getItem('authenticated') === 'false') {
    //   this.$router.push('/login');
    // }

    if (currentPath === '/' || currentPath === '/app') {
      this.$router.push('/dashboard');
    }
  },
  mounted() {
    
      if(!this.appConfig.option){
          
          //CONFIGURAR EM:          src/mixins/layout.js
          
      }
  },
};
</script>

<style src="./styles/theme.scss" lang="scss" />

