<template>
<div class="m-0 p-0">
	<vue-good-table :columns="columns"
					:rows="rows"
					:fixed-header="true"
					:xline-numbers="true"
					
					:sort-options="{
						enabled: true,
						initialSortBy: initialsort ? initialsort : {}
					}"

					:select-options="{ 
						enabled: selectable,
						selectOnCheckboxOnly: true,
						selectionText: 'linhas seleccionadas',
						disableSelectInfo: true, 
					}"

					@on-selected-rows-change="selectionChanged"
					

					:search-options="{
						enabled: searchable,
						placeholder: 'Pesquisar resultados',
					}"
					:pagination-options="{
						enabled: true,
						
						perPage: 25,
						position: 'bottom',
						perPageDropdown: [10, 25, 50],
						dropdownAllowAll: true,
						//setCurrentPage: 1,
						nextLabel: 'Seguinte',
						prevLabel: 'Anterior',
						rowsPerPageLabel: 'Linhas por página',
						ofLabel: 'de',
						pageLabel: 'página', // for 'pages' mode
						allLabel: 'Todos',
						
					}"

					@on-row-click="onRowClick" 
					@xon-row-dblclick="onRowClick" 
					
					styleClass="col-12 m-o p-o vgt-table striped"
					>

					<div slot="table-actions" v-if="hastableactions">
						<i class="fa fa-table"></i> 
					</div>
					<div slot="emptystate">
						Sem resultados a apresentar
					</div>

					<!-- ALTERAR CABEÇALHO DE COLUNA ACTION -->
					<template slot="table-column" slot-scope="props">
						<div v-if="props.column.label =='actions'"  style="width: 100%; text-align: center;">
							<i class="fa fa-cog"></i> 
						</div>
						<span v-else>
							{{props.column.label}}
						</span>
					</template>

					<!-- ALTERAR CONTEUDO DE CÉLULA ACTION -->
					
					<template slot="table-row" slot-scope="props">
						<div v-if="props.column.field == 'actions'" style="width: 100%; text-align: center;"   >
							<button class="btn btn-danger py-0" @click="action = 'delete'" role="delete"><i class="fa fa-trash" color="#900"></i></button>
						</div>
						<span v-else @on-cell-click="onRowClick">
							{{props.formattedRow[props.column.field]}}
						</span>
					</template>
	</vue-good-table>

<!-- 	
	<div>
		Linhas Seleccionadas:
		<br>
		<pre>
			{{selectedRows}}
		</pre>
	</div>  -->
	
</div>
</template>

<script>
export default {
name: 'vue-datatable',
props:{
	hastableactions:{
		type: Boolean,
		default: false,
	},
	initialsort:{
		// type: Object,
		default: () => {
			return false
		},
	},
	searchable:{
		type: Boolean,
		default: false,
	},
	selectable:{
		type: Boolean,
		default: false,
	},
	selectedRows:{
		type: Array,
		default: function () {
			return []
		}
	},
	columns: {
		type: Array,
		default: function () {
			return []
		}
	},
	rows: {
		type: Array,
		default: function () {
			return []
		}
	}
},
data(){
	return {
		action: '',
	};
},
mounted() {
	console.log(this.$refs);
},
computed: {
},
methods:{
	onRowClick(params) {
		// console.log(params.event.target.localName);

		switch (params.event.target.localName){
			case 'button':
			case 'i':  this.action = 'delete'; break;
			case 'input': this.action = 'select'; break;
			default: this.action = 'link'; break;
		}
		this.$emit('rowclick', {target: params.event, action: this.action, row: params.row});
		this.action = '';
		this.$forceUpdate();
		
		// console.log(params.event);
		// console.log(params.row);

		// params.row - row object 
		// params.pageIndex - index of this row on the current page.
		// params.selected - if selection is enabled this argument 
		// indicates selected or not
		// params.event - click event
	},
	selectionChanged(params){
		this.selectedRows = params.selectedRows;
		this.$forceUpdate();
	},
	onSelectAll(){
		alert('seleccionaste todas');
	}
}
};
</script>

<style lang="scss">
	table.vgt-table{
		font-size: 13px;
	}
	.vgt-global-search{
		background: transparent;
		border:none;
	}
	.vgt-global-search__input{
		margin-bottom: 45px;
	}
	table.vgt-table{
		background-color: transparent;
		border:none;
	}
	th.vgt-checkbox-col{
		background-color: thistle;
	}
	.vgt-table thead, .vgt-table thead  th{
		border-top: solid 1px #dedede;
		border-bottom: solid 1px #dedede;

		padding: 20px 00px;

		background: transparent;
		background-color: transparent;	
		font-weight: 300;
	}
	.vgt-inner-wrap{
		box-shadow: none;
	}
	.vgt-wrap__footer{
		background: transparent;
		border:none;
	}
	.vgt-checkbox-col{
		border:none !important;
		background: transparent !important;
	}
</style>