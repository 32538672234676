<template>
    <div class="dashboard">
        <b-row style="width:50%; float: right; margin-top: -50px; margin-bottom: 40px;">
            <b-col class="text-right"> 
                
                    <button class="btn btn-primary" @click="$router.push({ path: '/empresa/departamentos/novo', params: {} })">  Adicionar Departamento </button>
                
            </b-col>
        </b-row>

        <Widget class="h-100 mb-0" xtitle="Capanhas Ativas" xclose>
            <span v-if="!dados"> <i class="fa fa-spin fa-spinner"></i> </span>
            <div v-if="dados"> 
                <datatable class=" m-0 p-0" 
                    :selectable="false" 
                    :hastableactions="false" 
                    :searchable="true" 
                    @rowclick="rowclick_callback" 
                    :rows="dados"     
                    :columns="[ {label:'Departamento', field:'department'}, {label:'Diretor(a)', field:'manager'}, {label:'Nº Funcionários ', field:'no_employees'}]" 
                    :initialsort = "[{field: 'department', type: 'asc'}]"
                /> 
            </div>
        </Widget>
        <!-- 
        <b-row class="col-12">
            <div class="col-12" v-for="(d, k) in dados" :key="k">
                <pre @click="$router.push({ path: '/empresa/departamentos/'+d.id, params: {} })">{{d}}</pre>
            </div>
        </b-row> 
        -->
    </div>
</template>
    
<script>
    export default {
    name: 'DepartmentsList',
    props: ['teste'],
    components: {},
    data() {
        return {
            id: null,
            dados: {}
        };
    },
    computed: {

    },
    methods: {
        rowclick_callback(value){
            if(value.action == 'link') this.$router.push({ path: '/empresa/departamentos/'+value.row.id, params: {} });
        },
    },
    beforeMount() {
        
    },
    mounted() {   
    },
    created() {
        let self = this;
        self.api.get('company/departments/list').then(function(r){
                console.log(r);
                if(r.success){
                    self.$set(self, 'dados', r.dados);
                }else{
                    // self.$router.push('/empresa/departamentos');
                }
            });

    }
}
</script>
    