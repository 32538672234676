<template>
    <div class="col-12 row">
        <Widget class="col-12 row m-0 px-0 ">
            <form class="col-12 row m-0 p-0 pt-5" @submit.prevent="save">
                <fieldset class="col-9 row ">
                    <div class="formgroup col-12 p-0 row">
                        <label class="col-4" for="mec_number">Nº Mecanográfico</label>
                        <div class="col-8">
                            <input class="col-12" type="text" id="mec_number" v-model="dados.mec_number">
                            <p class="error" v-if="frm_log.error.mec_number">Por favor preencha este campo</p>
                        </div>
                    </div>

                    <div class="formgroup col-12 p-0 row">
                        <label class="col-4" for="">Nome</label>
                        <div class="col-8">
                            <input type="text" class="col-12" v-model="dados.name">
                            <span class="col-12 text-danger pt-1" v-if="frm_log.error.name">Campo obrigatório</span>
                        </div>
                    </div>

                    <div class="formgroup col-12 p-0 row">
                        <label class="col-4" for="">Email</label>
                        <div class="col-8">
                            <input type="email" class="col-8" v-model="dados.email">
                            <span class="text-danger pt-1" v-if="frm_log.error.email">Campo obrigatório</span>
                        </div>
                    </div>

                    <div class="formgroup col-12 p-0 row">
                        <label class="col-4" for="">Telefone</label>
                        <div class="col-8">
                            <input type="text" class="col-8" v-model="dados.telephone">
                            <span class="text-danger pt-1" v-if="frm_log.error.telephone">Campo obrigatório</span>
                        </div>
                    </div>
                    
                    <div class="formgroup col-12 p-0 row">
                        <label class="col-4" for="">Telemóvel</label>
                        <div class="col-8">
                            <input type="text" class="col-8" v-model="dados.mobilephone">
                            <span class="text-danger pt-1" v-if="frm_log.error.mobilephone">Campo obrigatório</span>
                        </div>
                    </div>

                    <div class="formgroup col-12 p-0 row">
                        <label class="col-4" for="">Data Nascimento</label>
                        <div class="col-8">
                            <input type="date" class="col-8" v-model="dados.birthday">
                            <span class="text-danger pt-1" v-if="frm_log.error.birthday">Campo obrigatório</span>
                        </div>
                    </div>

                    <hr class="col-10 my-3">



                    <div class="formgroup col-12 p-0 row">
                        <label class="col-4" for="">Departamento</label>
                        <div class="col-8 row">

                            <div class="col-12 pt-5 " v-if="frm_log.error.department_id && !dados.department_id">
                                <p class="error"> Por favor seleccione um departmento para este registo </p>
                            </div>
                            
                            <div class="pb-5 mx-0" :class="dados.department_id ? 'col-10' : 'col-12' ">
                                <div v-if="!new_department_form && !dados.department_id">
                                    
                                    <multiselect v-model="search.selected" open-direction="bottom" :options="search.list" label="department" track-by="department" :multiple="false" 
                                                :taggable="true" 
                                                :searchable="true" :loading="search.isLoading" :internal-search="false" :clear-on-select="true" :close-on-select="true" :options-limit="300" :limit="3" :max-height="600" :show-no-results="true" :hide-selected="false"
                                                    
                                                    placeholder="Pesquisar departamento"
                                                    tag-placeholder="Adicionar novo departamento" 
                                                    selectLabel="Selecionar departamento"
                                                    noOptions ="Sem resultados"
                                                    
                                                    @search-change="search_department"
                                                    @tag="add_department"
                                                    @select="select_department"
                                                >
                                                <!-- <span slot="noResult">Oops! No elements found. Consider changing the search query.</span> -->
                                                <template slot="noOptions"> Pesquisar por departmentos </template>
                                                <template slot="option" slot-scope="props">
                                                    <!-- <img class="option__image" :src="props.option.img" alt="No Man’s Sky"> -->
                                                    <div class="option__desc">
                                                        <span class="option__title">{{(props.option.id) ? props.option.id + ' - ' : ' '}} {{ props.option.department }}</span> 
                                                        <!-- <span class="option__title">{{props.option}}</span> -->
                                                    </div>
                                                </template>
            
                                    </multiselect>
            
                                </div>
                            
                                <!-- NEW department FORM -->
                                <div class="col-12 row m-0 p-0" v-if="new_department_form">
                                    <form class="col-12 row m-0 p-0" @submit.prevent="save_department" autocomplete="off">


                                        <div class="formgroup col-12 p-0 mt-4 row">
                                            <label class="col-12 col-md-4" for="department_name">Nome</label>
                                            <div class="col-12 col-md-8 p-0 m-0">
                                                <input class="col-12" type="text" id="department_name" v-model="new_department.name" required>
                                                <p class="error" v-if="frm_log.error.new_department.name">Por favor preencha este campo</p>
                                            </div>
                                        </div>
            
                                        <div class="formgroup col-12 p-0 mt-4  row">
                                            <label class="col-12 col-md-4" for="department_email">Email</label>
                                            <div class="col-12 col-md-8 p-0 m-0">
                                                <input class="col-12" type="email" id="department_email" v-model="new_department.email" required>
                                                <p class="error" v-if="frm_log.error.new_department.email">Por favor preencha este campo</p>
                                            </div>
                                        </div>
            
                                        <div class="formgroup col-12 p-0 mt-4  row">
                                            <label class="col-12 col-md-4" for="department_telephone">Telefone</label>
                                            <div class="col-12 col-md-8 p-0 m-0">
                                                <input class="col-12" type="text" id="department_telephone" v-model="new_department.phone">
                                                <p class="error" v-if="frm_log.error.new_department.phone">Por favor preencha este campo</p>
                                            </div>
                                        </div>
                                        <div class="col-12 my-5 text-center">
                                            <button class="btn-success px-3 py-2 " type="submit" v-if="!saving">Registar novo Departamento</button>
                                            <span class="btn-success" v-if="saving"><i class="fa fa-spinner fa-spin"></i></span>
                                        </div>
                                    </form>
                                </div>
            
            
                                <!-- department PROFILE -->
                                <div class="col-12 row" v-if="dados.department_id">
                                    {{dados.department}}
                                </div>
                            </div>
                            <div class="col-1 float-right" v-if="new_department_form ||dados.department_id != null"> 
                                <i class="fa fa-times" @click="reset_department"></i>
                            </div>
                        </div>
                    </div>
                    <div class="formgroup col-12 p-0 row">
                        <label class="col-4" for="">Categoria Profissional</label>
                        <div class="col-8">
                            <input type="date" class="col-8" v-model="dados.professional_category">
                            <span class="text-danger pt-1" v-if="frm_log.error.professional_category">Campo obrigatório</span>
                        </div>
                    </div>
                    <div class="formgroup col-12 p-0 row">
                        <label class="col-4" for="">Funcionário Desde</label>
                        <div class="col-8">
                            <input type="date" class="col-8" v-model="dados.employee_since">
                            <span class="text-danger pt-1" v-if="frm_log.error.employee_since">Campo obrigatório</span>
                        </div>
                    </div>

                </fieldset>

                <fieldset class="col-3 row m-0 p-0 justify-content-center ">
                    <img src="@/assets/imagens/no_photo.jpeg" alt="no_photo" style="height: 150px" @click="$refs.uploadProfilePic.click()"  v-if="!dados.profile_picture">
                    <img :src="appConfig.options.base_url+dados.profile_picture" @click="$refs.uploadProfilePic.click()" style="height: 150px;" v-if="dados.profile_picture">
                    <p class="col-12 text-center text-warning" @click="dados.profile_picture = null" v-if="dados.profile_picture">remover imagem de perfil</p>
                    <input type="file" @change="upload_profilepic" ref="uploadProfilePic" style="display:none;">
                    
                </fieldset>

                <div class="formgroup col-12 p-0 mt-5 pt-5 text-center">

                    <button class="px-5 btn border" type="submit" v-if="!saving">Guardar</button>
                    <span class="px-5 btn border" v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
                </div>
            </form>
        </Widget>
    </div>
    </template>
    
<script>
    import Multiselect from 'vue-multiselect'

    export default {
    name: 'EmployeeForm',
    props: ['teste'],
    components: {Multiselect},
    data() {
        return {
            search: {},
            new_department_form: false,
            new_department: {},


            frm_log: {},
            saving: false,
            id: null,
            dados: {}
        };
    },
    computed: {

    },
    methods: {

        //PHOTO 
        upload_profilepic(ev){
                    var self = this;
                    var temp = ev.target.files[0];
                    const fd = new FormData();
                    fd.append('path', '/staff/');
                    fd.append('file', temp, temp.name);
    
                    this.form.post('/Sys/Filehandle/upload', fd).then(function(r){      
                        self.$set(self.dados, 'profile_picture', r.dados.file.url);
                        self.$forceUpdate();
                    });
        },
        
        //DEPARTMENT HANDLING - SEARCH
        search_department(query){
                        var self = this;
                        console.log(query);
                        self.search.isLoading = true;
                        self.search.selected = null;
                        if(query && query.length){
                            // this.api.get('company/departments/search/'+query).then(function(response){
                            this.api.post('company/departments/search',{s:query}).then(function(response){
                                console.log(response);
                                
                                self.$set(self.search, 'list', (response.dados != null) ? response.dados : []);
                                self.$set(self.search, 'isLoading', false);
                                
                            });
                        }else{
                            self.$set(self.search, 'list', []);
                            self.$set(self.search, 'isLoading', false);
                        }

                    },
                    select_department(data){
                        console.log(data)
                        this.search.selected = null;
                        this.$set(this.dados,'department_id',data.id);
                        this.$set(this.dados,'department', data.department);
                    },

                    add_department (querystring) {
                        let self = this;
                        console.log(querystring);
                        // this.new_department_form = true;
                        // this.$set(this.new_department, 'name', querystring);

                        // this.$forceUpdate();
                        self.api.post('company/departments/quickSave',{department:querystring}).then(function(response){
                                console.log(response);
                                
                                self.$set(self.dados,'department_id',response.dados.id);
                                self.$set(self.dados,'department', response.dados.department);
                                self.$forceUpdate();
                                
                        });


                    },

                    reset_department(){
                        this.search.selected = null;
                        this.new_department_form = false; 
                        this.dados.department_id = null
                        this.$forceUpdate;
                    },

        save(){
            let self = this;

            // self.$set(self, 'saving', true);
            
            self.$set(self.dados, 'active', 1);

            self.api.post('company/employees/save', self.dados).then(function(r){
                    console.log(r);
                    
                    if(r.success){
                        self.$router.push('/empresa/recursos-humanos/'+r.dados.id);
                    }else{
                        self.frm_log.error = r.error;
                    }
                    self.frmLog(r.success, r.success ? 'Dados Atualizados com sucesso' : 'Ocorreu um erro no processamento do seu pedido');
                    self.$set(self, 'saving', false);
            });


        }
    },
    
    beforeMount() {
        
    },
    mounted() {   
    },
    created() {
        this.frm_log.error = [];
        this.frm_log.error.new_department = [];
        // this.frm_log.error.name = true;
        // this.frm_log.error.telephone = true;
        
        this.$set(this.search,'isLoading', false);
        this.$set(this.search,'list', []);
        this.$set(this.search,'selected', null);

        
        if(this.$route.params.id) {
            let self = this;
            self.id = this.$route.params.id;
            self.api.get('company/employees/'+self.id).then(function(r){
                console.log(r);
                if(r.success){
                    self.dados = r.dados;
                }else{
                    self.$router.push('/empresa/recursos-humanos');
                }
            });
        }
    }
}
</script>


<style>

</style>
    