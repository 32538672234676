<template>
    <div class="auth-page">
      <b-container class=" text-center" v-if="!allow_register">
        <p class="col-12 row text-center" style="width:100%; text-align:center; font-size: 24px" > registos encerrados.</p>
      </b-container>
      <b-container v-if="allow_register">
        <h5 class="auth-logo">
          <!-- <i class="fa fa-circle text-primary"></i> -->
          <img :src="appConfig.options.base_url+appConfig.options.brand_logo" alt="" style="height: 75px;"  v-if="appConfig.options && appConfig.options.brand_logo">
          <img src="../../assets/imagens/logo.png" alt="" style="height: 75px;" v-else="">
          <!-- <i class="fa fa-circle text-danger"></i> -->
        </h5>
        <Widget class="widget mx-auto text-center" title="" customHeader>

          <form class="mt" @submit.prevent="register"> 


            <!-- DADOS PESSOAIS -->
            <fieldset class="col-12 row">
              <label class="col-12 border-bottom pb-3 text-left" for="">Dados Pessoais</label>

<!-- 
              <div class="formgroup col-12 row">
                <label class="col-4" for="frmNationality">Nacionalidade</label>
                <select class="col-8" v-model="profile.nationality" id="" required>
                    <option :value="c.ISO3166_1_Alpha_2" v-for="(c, k) in countries" :key="k">{{c.PT}}</option>
                </select>
                <div class="info col-12 d-flex justify-content-end">
                    <p class="col-8">
                    </p>
                </div>
              </div> -->
    
              <div class="formgroup col-12 row">
              <label class="col-4" for="frmNome">Nome</label>
              <!-- <select class="col-2" v-model="profile.title" id="">
                  <option value=""></option>
                  <option value="Dr">Dr.(a)</option>
                  <option value="Eng">Eng.(a)</option>
              </select> -->
              <input id="frmNome" class="col" v-model="profile.name" type="text" required/>
              <div class="info col-12 d-flex justify-content-end">
                  <p class="col-8">
                  <small><em></em></small>
                  </p>
              </div>
              </div>

              <!-- <div class="formgroup col-12 row">
              <label class="col-4" for="frmVat">NIF</label>
              <input id="frmVat" class="col-7" v-model="profile.vat" type="text" required/>
              <div class="info col-12 d-flex justify-content-end">
                  <p class="col-8">
                  </p>
              </div>
              </div> -->

              <div class="formgroup col-12 row">
              <label class="col-4" for="frmDefaultPhone">Telefone</label>
              <select class="col-2 text-center" v-model="profile.phone_prefix" id="">
                  <option :value="c.Dial" v-for="(c, k) in phone_prefix" :key="k">+{{c.Dial}}</option>
              </select>
              <input id="frmDefaultPhone" class="col" v-model="profile.telephone" type="text" placeholder="Telefone " required/>
              <div class="info col-12 d-flex justify-content-end">
                  <p class="col-8">
                  </p>
              </div>
              </div>

              <!-- <div class="formgroup col-12 row">
              <label class="col-4" for="frmDNasc">Data Nascimento</label>
              <input id="frmDNasc" class="col-4 text-center" v-model="profile.birthdate" type="date" required/>
              <div class="info col-12 d-flex justify-content-end">
                  <p class="col-8">
                  </p>
              </div>
              </div> -->
              
              <!--   
              <div class="formgroup col-12 row">
              <label class="col-4" for="frmGenero">Género</label>
              <label class="col-4" for="frmGeneroMasc"> Masculino <input id="frmGeneroMasc" class="col-8" v-model="profile.gender" value="m" type="radio" required/> </label>
              <label class="col-4" for="frmGeneroFem"> Feminino <input id="frmGeneroFem" class="col-8" v-model="profile.gender" value="f" type="radio" required/> </label>
              <div class="info col-12 d-flex justify-content-end">
                  <p class="col-8">
                  </p>
              </div>
              </div> -->

          </fieldset>
          <!-- FIM DADOS PESSOAIS -->

          <!-- DADOS CONTA -->          
            <fieldset class="col-12 row mt-5">
              <label class="col-12 border-bottom pb-3 text-left" for="">A sua Conta</label>
  
              <div class="formgroup col-12 row">
              <label class="col-4" for="frmEmail">Email</label>
              <input id="frmEmail" class="col-8" v-model.lazy="profile.email" type="email" placeholder="@" required/>
              <div class="info col-12 d-flex justify-content-end">
                  <p class="col-8">
                  </p>
              </div>
              </div>
  
              <div class="formgroup col-12 row">
              <label class="col-4" for="frmEmailConfirm">Confirme o Email</label>
              <input id="frmEmailConfirm" class="col-8" v-model.lazy="confirm_fields.email" type="email" placeholder="@" required/>
              <div class="info col-12 d-flex justify-content-end">
                  <p class="col-8"></p>
                  <p class="col-8 error" v-if="frmerror.email"><em>Os endereços introduzidos não coincidem.</em></p>
                  
              </div>
              </div>
  
              <div class="formgroup col-12 row">
              <label class="col-4" for="frmAuthPassword">Senha</label>
              <input id="frmAuthPassword" class="col-8" v-model.lazy="profile.pwd" type="password" placeholder="" required/>
              <div class="info col-12 d-flex justify-content-end">
                  <p class="col-8">
                  </p>
              </div>
              </div>
  
              <div class="formgroup col-12 row">
              <label class="col-4" for="frmAuthPasswordConfirm">Confirme a Senha</label>
              <input id="frmAuthPasswordConfirm" class="col-8" v-model.lazy="confirm_fields.pwd" type="password" placeholder="" required/>
              <div class="info col-12 d-flex justify-content-end">
                  <p class="col-8"></p>
                  <p class="col-8 error" v-if="frmerror.pwd"><em>As senhas introduzidas não coincidem.</em></p>
                  
              </div>
              </div>
          </fieldset>
          <!-- FIM DADOS CONTA -->
  
          
  
          <!-- ENDEREÇO -->
          <!--
          <fieldset class="col-12 row mt-5">
              <label class="col-12 border-bottom pb-3 text-left" for="">Endereço</label>
              
              <div class="formgroup col-12 row">
              <label class="col-4" for="frmAddress">Morada</label>
              <input id="frmAddress" class="col-8" v-model="profile.address" type="text" />
              <div class="info col-12 d-flex justify-content-end">
                  <p class="col-8">
                  </p>
              </div>
              </div>
  
              <div class="formgroup col-12 row">
              <label class="col-4" for="frmPostcode">Cod Postal</label>
              <input id="frmPostcode" class="col-8" v-model="profile.postcode" type="text" required/>
              <div class="info col-12 d-flex justify-content-end">
                  <p class="col-8">
                  </p>
              </div>
              </div>
  
              <div class="formgroup col-12 row">
              <label class="col-4" for="frmCity">Cidade</label>
              <input id="frmCity" class="col-8" v-model="profile.city" type="text" required/>
              <div class="info col-12 d-flex justify-content-end">
                  <p class="col-8">
                  </p>
              </div>
              </div>
  
              <div class="formgroup col-12 row">
              <label class="col-4" for="frmCountry">País</label>
              <select class="col-8" v-model="profile.country" id="">
                  <option :value="c.PT" v-for="(c, k) in countries" :key="k">{{c.PT}}</option>
              </select>
              <div class="info col-12 d-flex justify-content-end">
                  <p class="col-8">
                  </p>
              </div>
              </div>
          </fieldset>
        -->
          <!-- FIM ENDEREÇO -->
            
          <b-button type="submit" size="sm" class="auth-btn mb-3" variant="inverse">Registo</b-button>

          </form>
          <p class="widget-auth-info">
            Já tem uma conta?
          </p>
          <router-link class="d-block text-center" to="/login">Autentique-se</router-link>
          
        </Widget>
      </b-container>
      <footer class="auth-footer">
        2020 &copy; Lab 7.
      </footer>
    </div>
  </template>
  
  <script>
  import Widget from '@/components/Widget/Widget';
  import countrylist from '@/data/country-list.json';


  export default {
    name: 'Registo',
    components: { Widget },
    data() {
      return {
        countries: countrylist,

        frmerror:{},

        newAccount: true,
        hashAccount: null,
        account: {},
        profile: {},
        confirm_fields: {}
        
      };
    },
    computed: {
          allow_register(){
            return (this.appConfig.options && this.appConfig.options.allow_registration == 1) ? true : false;
          },
          errorMessage () {
            return null
          },
          phone_prefix(){
            return this.countries.slice(0).sort((a, b) => (a.Dial > b.Dial) ? 1 : -1);
        }
    },

    watch: {
        'profile.email': function (){
            this.validateConfirmFields();
        },
        'profile.pwd': function (){
            this.validateConfirmFields();
        },
        confirm_fields: {
            deep: true,
            handler() {this.validateConfirmFields()}
        }
    },
    
    methods: {

        //***//
        validateConfirmFields(){
            let self = this;
            // console.log('entrei na validação');
            Object.keys(this.confirm_fields).forEach(k => {
                let v = self.confirm_fields[k];

                if(v.length > 0){
                    if(v != self.profile[k]){
                        // console.log(v+' != '+self.profile[k]);
                        self.frmerror[k]= true;
                    }else{
                        // console.log(v+' == '+self.profile[k]);
                        if(self.frmerror[k]) { self.$delete(self.frmerror, k);}
                    }
                }else{
                    // console.log(v+' == '+self.profile[k]);
                    self.frmerror[k]= false;
                    if(self.frmerror[k]) { self.$delete(self.frmerror, k); }
                }
            })

        },
        register() {

          let self = this;
            
          this.api.post('users/register', this.profile).then(function(response){

            if(response.success){


              self.$router.push('/login');

            }else{
                self.frmerror  = response.error;
            }
            console.log(response);
          });

      
            

        },
    },
    created() {
      if (window.localStorage.getItem('authenticated') === 'true') {
        this.$router.push('/dashboard');
      }
      //init PT 
      this.profile.nationality = 'PT';
      this.profile.country = 'Portugal';
      this.profile.phone_prefix = '351';

      this.$forceUpdate();
    },
    mounted() {
      
    }
  };
  </script>
  
  <style type="less" scoped>
    .auth-page{
      width: 100vw;
      min-height: 100vh;
      margin-bottom: 100px;
      background: url(../../assets/imagens/bg.jpg); 
      background-size: cover; 
      background-position:center;
      position: fixed;
      overflow: hidden;
      overflow-y: scroll;
      
    }
    .widget{
      background-color: rgba(255,255,255, 0.9);
    }
  </style>