<template>
    <div class="componente">
        <router-view :teste='teste'/>
    </div>
    </template>
    
<script>
    export default {
    name: 'Department',
    components: {},
    data() {
        return {
            teste: 123
        };
    },
    computed: {

    },
    methods: {

    },
    
    beforeMount() {
        // alert('vou arrancar módulo RH')
    },
    mounted() {   
    },
    created() {
        if(this.$route.params.id) {this.id = this.$route.params.id;}
    }
}
</script>
    