<template>
    <div class="col-12 row m-0 p-0">
        <!-- Form {{id ? 'editar ' + id : 'novo'}} -->
        <div class="d-flex align-items-center justify-content-center col-12 row" v-if="loading" style="height: 80vh; width:100%;">
            <div class="col-12 text-center">
                <i class="fa fa-spin fa-spinner fa-4x"></i>
            </div>
        </div>

        <form class="col-12 row m-0 p-0 pt-5" @submit.prevent="save" v-if="!loading">
        <Widget>

            <div :class="data.course_id ? 'col-12' : 'col-12'">
                

                    <div class="formgroup col-12 row ">
                        <label class="col-4" for="">Entidade</label>
                        <div class="col-8 row m-0 p-0">
                            <multiselect v-model="data.entity" 
                                        :options="entitieslist" 
                                        
                                        :multiple="false" 
                                        :taggable="true" 
                                        :searchable="true"
                                        :close-on-select="true"

                                        label="name"
                                        track-by="id"
                                        placeholder="Seleccione uma Entidade"  
                                        
                                        tag-placeholder="Adicionar nova Entidade" 
                                        selectLabel="Selecionar entidade"
                                        noOptions ="Sem resultados"
                                        @tag="addEntity"
                            >
                                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong> </template>
                            </multiselect>
                            <p class="error text-danger" v-if="frmerror.course_id"> Campo obrigatório</p>
                        </div>
                    </div>


                    <div class="formgroup col-12 row ">
                        <label class="col-4" for="">Curso</label>
                        <div class="col-8 row m-0 p-0">
                            <input type="text" v-model="data.title" required>
                            <p class="error text-danger" v-if="frmerror.title"> Campo obrigatório</p>
                        </div>
                    </div>

                    <div class="formgroup col-12 row ">
                        <label class="col-4" for="">Sigla (ou abreviatura)</label>
                        <div class="col-8 row m-0 p-0">
                            <input type="text" v-model="data.short_title">
                            <p class="error text-danger" v-if="frmerror.short_title"> Campo obrigatório</p>
                        </div>
                    </div>


                    <div class="formgroup col-12 row ">
                        <label class="col-4" for="">Tipologia</label>
                        <div class="col-8 row m-0 p-0">
                            <select v-model="data.type_id" >
                                <option :value="type.id" v-for="type in options.course_types" :key="type.id">{{type.title}}</option> 
                            </select>
                            <p class="error text-danger" v-if="frmerror.course_id"> Campo obrigatório</p>
                        </div>
                    </div>

                    <div class="formgroup col-12 row ">
                        <label class="col-4" for="">Categoria</label>
                        <div class="col-8 row m-0 p-0">
                            <select v-model="data.category_id" >
                                <option :value="cat.id" v-for="cat in options.course_categories" :key="cat.id">{{cat.title}}</option> 
                            </select>

                            <p class="error text-danger" v-if="frmerror.course_id"> Campo obrigatório</p>
                        </div>
                    </div>


                    <div class="formgroup col-12 row ">
                        <label class="col-4" for="">Objetivos</label>
                        <div class="col-8 row m-0 p-0">
                            <textarea class="col-12" rows="6" v-model="data.objectives"></textarea>
                            <p class="error text-danger" v-if="frmerror.objectives"> Campo obrigatório</p>
                        </div>
                    </div>
                    
            </div>

            <!-- 
            <div class="col-4" v-if="data.course_id">
                <Widget>

                </Widget>
            </div> 
            -->


            <div class="col-12 my-3 pt-5 ">
                
                <div class="formgroup col-12 text-center">
                    <button class="btn btn-save border px-5" type="submit" v-if="!saving">Guardar</button>
                    <span class="btn btn-save px-5 border" v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
                </div>
                
            </div>
        
        </Widget>
        </form>


    </div>
</template>
    
<script>
    // import SearchCourse from '../partials/search_course';
    import Multiselect from 'vue-multiselect'

    export default {
    name: 'Form',
    // components: {SearchCourse},
    components: {Multiselect},
    props: ['options', 'courseslist', 'entitieslist'],
    data() {
        return {
            frmerror: {},
            saving: false,
            loading: false,
            
            data: {}
        };
    },
    computed: {
        id(){
            let id = this.$route.params.id || null;
            return id;
            // return Number.isInteger(id) ? id : null
        }
    },
    watch:{
        id(val){
            let self = this;
            self.$set(this, 'loading', true);
            alert('id = ' + val);
            setTimeout(function (){
                self.$set(self, 'loading', false);
            }, 5000); 
        },
        'data.entity'(){
            this.$set(this.data, 'entity_id', this.data.entity.id);
        },
        'data.entity_id'(){
            
            const found = this.entitieslist.find(element => element.id = this.data.entity_id);
            if(!this.data.entity || !this.data.entity.id || this.data.entity.id != this.data.entity_id){
                this.$set(this.data,'entity', found);
            }
        },  
        'entitieslist'(){
            
            const found = this.entitieslist.find(element => element.id = this.data.entity_id);
            if(!this.data.entity || !this.data.entity.id || this.data.entity.id != this.data.entity_id){
                this.$set(this.data,'entity', found);
            }
        }   
    },
    methods: {
        addEntity(querystring){
            let self = this;
            self.api.post('tad/save_entity', {name:querystring}).then((r)=>{
                // console.log(r);
                self.entitieslist.push({id:r.dados, name:querystring});
                setTimeout(function (){
                    self.$set(self.data, 'entity_id', r.dados);
                }, 1000); 

                self.$forceUpdate();
            });
        },
        save(){
            let self = this;
            self.saving = true;
            
            
            self.api.post('tad/courses/save', self.data).then( (r)=>{
                if(r.success){
                    self.saving = false;
                    this.$emit('updatecourses');
                    this.$emit('updateentities');
                    this.$router.go(-1);
                }else{
                    self.frmerror = r.error;
                }
            });


            
        }
    },
    beforeMount() {
        
    },
    mounted() {   

        let self = this; 
        if(this.$route.params.title) {this.$set(this.data, 'title', this.$route.params.title);}
        
        if(this.$route.params.id) {
            self.api.get('tad/courses/get_course/'+this.id).then((r)=>{
                console.log(r);
                if(r.success){
                    self.$set(self, 'data', r.dados);
                }
            });
        }
        

    },
    created() {
        
    }
}
</script>
    