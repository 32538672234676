<template>
<div class="col-12 row">
            <form class="col-12 row m-0 p-0" @submit.prevent="save">
                
                <fieldset class="col-12 row">
                    <label class="col-12 border-bottom pb-3 text-left mb-5" for="">Dados Sistema</label>

                    <div class="formgroup col-12 row py-3">
                        <label class="col-4" for="frmNomeProjeto">Nome do Projeto</label>
                        <input id="frmNomeProjeto" class="col-8" v-model.lazy="info.sitename" type="text" placeholder="Nome do Projeto" required/>
                        <div class="info col-12 d-flex justify-content-end">
                            <p class="col-8 error" v-if="frmerror.sitename"><em>campo obrigatório.</em></p>
                        </div>
                    </div>

                    

                    

                    <div class="formgroup col-12 row py-3">
                        
                        <label class="col-4" for="frmNomeProjeto">Logotipo</label>
                        <div class="col-8">
                            <input type="file" @change="upload_logo" ref="uploadLogo" style="display:none;">
                            <img :src="info.brand_logo ? info.base_url+info.brand_logo : './assets/imagens/logo.png'" @click="$refs.uploadLogo.click()" style="height: 35px;">
                        </div>
                        <!-- <input id="frmNomeProjeto" class="col-8" v-model.lazy="info.brand_logo" type="url" placeholder="Logotipo do Projeto" required/> -->
                        <div class="info col-12 d-flex justify-content-end">
                            <p class="col-8 error" v-if="frmerror.brand_logo"><em>campo obrigatório.</em></p>
                        </div>
                    </div>



                    <div class="formgroup col-12 row py-3">
                        <label class="col-4" for="brand_icon">Icone</label>
                        <div class="col-8">
                            <input type="file" @change="upload_icon" ref="uploadIcon" style="display:none;">
                            <img :src="info.brand_icon ? info.base_url+info.brand_icon : './assets/imagens/logo.png'" @click="$refs.uploadIcon.click()" style="height: 35px;">
                        </div>
                        <div class="info col-12 d-flex justify-content-end">
                            <p class="col-8 error" v-if="frmerror.brand_icon"><em>campo obrigatório.</em></p>
                        </div>
                    </div>


                    <div class="formgroup col-12 row py-3 ">        
                        <label class="col-4" for="alowregistration">Permite Registo</label>
                        <label class="col-3" for="allowregistration_true"><input id="allowregistration_true" v-model.lazy="info.allow_registration" type="radio" value="1" required/> Sim </label>
                        <label class="col-3" for="allowregistration_false"><input id="allowregistration_false" v-model.lazy="info.allow_registration" type="radio" value="0" required/> Não </label>
                        <div class="info col-12 d-flex justify-content-end">
                            <p class="col-8 error" v-if="frmerror.sitename"><em>campo obrigatório.</em></p>
                        </div>
                    </div>


                </fieldset>

                <fieldset class="col-12 row mt-5">
                    <label class="col-12 border-bottom pb-3 text-left my-5" for="">Dados Administrador / Suporte</label>


                    <div class="formgroup col-12 row py-3">
        
                        <label class="col-4" for="frmNomeProjeto">Equipa Suporte</label>
                        <input id="frmNomeProjeto" class="col-8" v-model.lazy="info.support_team" type="text" placeholder="Equipa Suporte" required/>
                        <div class="info col-12 d-flex justify-content-end">
                            <p class="col-8 error" v-if="frmerror.developed_by"><em>campo obrigatório.</em></p>
                        </div>
                    </div>



                    <div class="formgroup col-12 row py-3">
        
                        <label class="col-4" for="frmNomeProjeto">Website Suporte</label>
                        <input id="frmNomeProjeto" class="col-8" v-model.lazy="info.support_website" type="text" placeholder="Website" required/>
                        <div class="info col-12 d-flex justify-content-end">
                            <p class="col-8 error" v-if="frmerror.support_website"><em>campo obrigatório.</em></p>
                        </div>
                    </div>

                    
        
                        <div class="formgroup col-12 row py-3 ">
                                <label class="col-4" for="frmEmailConfirm">Email Suporte</label>
                                <input id="frmEmailConfirm" class="col-8" v-model.lazy="info.support_email" type="email" placeholder="Email" required/>
                                <div class="info col-12 d-flex justify-content-end">
                                    <p class="col-8 error" v-if="frmerror.support_email"><em>campo obrigatório.</em></p>
                                </div>

                        </div>

                        <div class="formgroup col-12 row py-3 ">
                                <label class="col-4" for="frmEmailConfirm">Telefone Suporte</label>
                                <input id="frmEmailConfirm" class="col-8" v-model.lazy="info.support_phone" type="text" placeholder="Telefone" required/>
                                <div class="info col-12 d-flex justify-content-end">
                                    <p class="col-8 error" v-if="frmerror.support_phone"><em>campo obrigatório.</em></p>
                                </div>

                        </div>
                        
                </fieldset>

                <fieldset class="col-12 text-center mt-5">
                    <button type="submit" class="btn border px-5 py-2">Seguinte</button>
                </fieldset>
            </form>
</div>
</template>

<script>
export default {
name: 'step01',
props: ['info'],
components: {},
data() {
    return {
        frmerror: {},
        loading: false
    };
},
computed: {
},
methods: {
        upload_logo(ev){
        // this.uploadFile(ev).then( (r) => {console.log(r);});

                var self = this;
                var temp = ev.target.files[0];
                const fd = new FormData();
                fd.append('path', '/');
                fd.append('file', temp, temp.name);

                this.form.post('/Sys/Filehandle/upload', fd).then(function(r){      
                    self.$set(self.info, 'base_url', r.dados.file.base_url);              
                    self.$set(self.info, 'brand_logo', r.dados.file.url);
                    self.$forceUpdate();
                });
        
    },
    upload_icon(ev){
        // this.uploadFile(ev).then( (r) => {console.log(r);});

                var self = this;
                var temp = ev.target.files[0];
                const fd = new FormData();
                fd.append('path', '/');
                fd.append('file', temp, temp.name);

                this.form.post('/Sys/Filehandle/upload', fd).then(function(r){                    
                    self.$set(self.info, 'base_url', r.dados.file.base_url);
                    self.$set(self.info, 'brand_icon', r.dados.file.url);
                    self.$forceUpdate();
                });
        
    },
    save(type) {
        this.api.post('sys/config/save', this.info).then((r) => {
            console.log(r);
            if(r.success){
                this.$emit('next', type);
            }    
        });
        
    }
},
created() {
    
},
mounted() {    
    this.$set(this.info, 'allow_registration', 0);
    this.$forceUpdate();
}
};
</script>
