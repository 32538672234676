<template>
    <div class="cp">
        <b-row style="width:50%; float: right; margin-top: -50px; margin-bottom: 40px;">
            <b-col class="text-right"> 
                
                    <button class="btn btn-primary" @click="$router.push({ path: '/empresa/departamentos/'+id+'/edit', params: {} })">  Editar Departamento </button>
                    <button class="btn btn-danger ml-3" @click="eliminar()">  <i class="fa fa-trash"></i> </button>
                
            </b-col>
        </b-row>
        <!-- <br> Teste : {{teste}} -->

            
        <b-row class="col-12 m-0 p-0">
            <b-col  xs="12">
            <Widget>
                <div class="formgroup col-12 p-0 row">
                    <label class="col-4" for="mec_number">Departamento</label>
                    <div class="col-8">
                        {{dados.department}}
                    </div>
                </div>


                <div class="formgroup col-12 p-0 row">
                    <label class="col-4" for="mec_number">Responsável</label>
                    <div class="col-8">
                        {{dados.manager || 'sem responsável associado'}}
                    </div>
                </div>


            </Widget>
        </b-col>


        </b-row>

        <b-row class="col-12 m-0 p-0">
        <b-col xs="12">
            <Widget>
            <div class=" panel p-0 m-0">
                <tabs>
                    <tab class="col" name="Certificados" :title="(dados.employees ? dados.employees.length : 0) +' Funcionário(s)'" :selected="true">
                        <!-- <ContactLog :contact_log = 'dados.certificates'></ContactLog> -->
                        <!-- <pre>{{certificados}}</pre> -->

                        <vue-good-table  :columns="[{field: 'profile_picture', label:' ', 'sortable':false, 'html':true}, {field: 'name', label: 'Nome' }, {field: 'phone', label: 'Telefone' }, {field: 'email', label: 'Email' }, {field:'certified_tad', label:'Horas Formação'}, {field:'action', label:'', 'sortable': false, 'html': true}]"
                                    :rows="funcionarios"
                                    :sort-options="{
                                        enabled: true,
                                        initialSortBy: {field: 'name', type: 'asc'}
                                    }"
                                    styleClass="col-12 m-o p-o vgt-table striped"
                        >
                        </vue-good-table>
                    </tab>
                </tabs>
            </div>
            </Widget>
        </b-col>
        </b-row>

<!-- 
        <b-row class="col-12">
            <b-col>
                <pre>
                    {{dados}}
                </pre>
            </b-col>
        </b-row> -->
    </div>
</template>
    
<script>
    export default {
    name: 'Employee',
    props: ['teste'],
    components: {},
    data() {
        return {
            id: null,
            dados: {}
        };
    },
    computed: {
        funcionarios(){
            let funcionarios = [];
            
            if(Array.isArray(this.dados.employees)){
                this.dados.employees.forEach(e => {
                    let temp = e;
                    temp.phone  = ((e.telephone) ? e.telephone+' ' : '') 
                                + ((e.mobilephone && e.telephone) ? ' / ' : '')
                                + ((e.mobilephone) ? e.mobilephone+' ' : '');

                    if(e.profile_picture){
                        temp.profile_picture = '<img src="'+this.appConfig.options.base_url + e.profile_picture +'" class="profile_pic"/>';            
                    }else{
                        // temp.profile_picture = '<img src="@/assets/imagens/no_photo.jpeg" class="profile_pic"/>';            
                        temp.profile_picture = null;
                    }

                    // temp.action = '<button class="btn" @click="$router.push({ path: \'/empresa/recursos-humanos/\''+e.id+', params: {} })"> <i class="fa fa-user"></i> </button>';
                    temp.action = '<a href="../recursos-humanos/'+e.id+'"> <i class="fa fa-user"></i> </a>';

                    
                    funcionarios.push(temp);

                });
            }
            return funcionarios;
        }
    },
    methods: {
        eliminar(){
            let self = this;
            

            
                this.swal.fire({
                    title: 'De certeza que pretende eliminar o departamento  '+ self.dados.department +'?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: `Eliminar`,
                    denyButtonText: `Cancelar`,
                    }).then((result) => {
                    if (result.isConfirmed) {
                        self.api.delete('company/departments/'+self.id).then(function(r){
                            if(r.success){ self.$router.push('/empresa/departamentos');
                            }else{
                                // self.$router.push('/empresa/recursos-humanos/');
                            }
                        });


                    } else if (result.isDenied) {
                        //do Nothing.
                    }
                    })

            

        }
    },
    
    beforeMount() {
        
    },
    mounted() {   
    },
    created() {
        if(this.$route.params.id) {
            let self = this;
            self.id = this.$route.params.id;
            self.api.get('company/departments/'+self.id).then(function(r){
                console.log(r);
                if(r.success){
                    self.dados = r.dados;
                }else{
                    // self.$router.push('/empresa/departamentos');
                }
            });
        }
    }
}
</script>
<style>
    .profile_pic{
        width: 30px;
    }
</style>