<template>
    <div>
        <div class="tabs">
          <ul class="nav nav-tabs tabs f32 d-flex" role="tablist" >
            <li role="presentation" class="col tab" v-for="(tab,k) in tabs" :key="k" :class="{ 'active': tab.isActive } " >
                <a :class="{ 'active': tab.isActive } " :href="tab.href" @click="selectTab(tab)">{{ tab.title ? tab.title : tab.name }}</a>
            </li>
            <div class="indicator"></div>
          </ul>
        </div>
        
        

        <div class="tab-content">
            <slot></slot>
        </div>

        
    </div>
</template>

<script>
    export default {
        name: 'Tabs',
        data: function() {
            return {tabs: [] };
    },
    created() {
        this.tabs = this.$children;
    },
    methods: {
        selectTab(selectedTab) {
            this.tabs.forEach(tab => {
                tab.isActive = (tab.name == selectedTab.name);
            });
        }
    }

    };
    </script>
    
    <style src="./_tabs.scss" lang="scss" />
    