<template>
  <div class="auth-page">
    <b-container>
      <h5 class="auth-logo">
        <!-- <i class="fa fa-circle text-primary"></i> -->
        
        <img :src="appConfig.options.base_url+appConfig.options.brand_logo" alt="" style="height: 75px;"  v-if="appConfig.options && appConfig.options.brand_logo">
        <img src="../../assets/imagens/logo.png" alt="" style="height: 75px;" v-else="">
        <!-- <i class="fa fa-circle text-danger"></i> -->
      </h5>
      <Widget class="widget-auth mx-auto text-center" title="" customHeader>
        <p class="widget-auth-info">
            Utilize o seu email para o inicio de sessão.
        </p>
        <form class="mt" @submit.prevent="login">
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{errorMessage}}
          </b-alert>
          <div class="form-group">
            <input class="form-control no-border" ref="email" required type="email" name="email" placeholder="Email" />
          </div>
          <div class="form-group">
            <input class="form-control no-border" ref="password" required type="password" name="password" placeholder="Password" />
          </div>
          <b-button type="submit" size="sm" class="auth-btn mb-3" variant="inverse">Login</b-button>
          <!-- <p class="widget-auth-info">ou autentique-se com a sua conta </p>
          <div class="social-buttons">
            <b-button variant="primary" class="social-button mb-2">
              <i class="social-icon social-google"></i>
              <p class="social-text">GOOGLE</p>
            </b-button>
            <b-button variant="success" class="social-button" @click="$msal.signIn()">
              <i class="social-icon social-microsoft"></i>
              <p class="social-text">MICROSOFT</p>
            </b-button>
          </div> -->
        </form>


        <p class="widget-auth-info" v-if="allow_register">
          Ainda não tem uma conta?
          <router-link class="d-block text-center" to="/registo" >Registe-se</router-link>
        </p>
        
      </Widget>
    </b-container>
    <footer class="auth-footer">
      2020 &copy; LAB 7.
    </footer>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';

export default {
  name: 'LoginPage',
  components: { Widget },
  data() {
    return {
      auth: {},
      errorMessage: null,
    };
  },
  computed: {
    allow_register(){
      return (this.appConfig.options.allow_registration == 1) ? true : false;
    }
    // config(){
    //   return this.appConfig;
    // }
  },
  methods: {
    
    login() {
      const self = this;
      const email = this.$refs.email.value;
      const password = this.$refs.password.value;
      this.$set(this.auth, 'email', email);
      this.$set(this.auth, 'pwd', password);
      // const user = {name: 'Utilizador Exemplo', email: email, pwd: password};

      // if (email.length !== 0 && password.length !== 0) {
      //   window.localStorage.setItem('authenticated', true);

      //   window.localStorage.setItem('jwt', 'exemplo de token de sessão');
      //   window.localStorage.setItem('user', window.jsonxEnc(user));
        
      //   this.$router.push('/dashboard');
      // }



      this.api.post('Sys/Auth/login', this.auth).then(function(response){

        if(response.dados.success){
            window.localStorage.setItem('authenticated', true);

            window.localStorage.setItem('jwt', response.dados.token);
            window.localStorage.setItem('user', window.jsonxEnc(response.dados.user));
        
            self.$store.state.user.authenticated = true;
            self.$store.state.user.permissions = response.dados.user.permissions;

            self.$router.push('/dashboard');

        }else{
            self.errorMessage  = response.dados.log;
        }
        console.log(response);
      });

    },
  },
  beforeCreate() {
  },
  created() {
    // console.log(this.appConfig);
    if(!this.appConfig.options){
        let self = this;
        
      
          this.api.get('sys/config/').then( (r) => {
            self.appConfig.options={}; 
            if(r.dados){
              r.dados.forEach((v) => {
                self.$set(self.appConfig.options, v.key, v.value);
                // console.log(v.key+ ' = ' + v.value);
              });

             

            }
            self.$forceUpdate();
              if(!self.appConfig.options.installed){
                  self.$router.push('/install');
                }/*else{
                  self.$router.push('/login');
                }*/

              if(self.appConfig.options.sitename){
                //CHANGE DOCUMENT TITLE
                document.title = self.appConfig.options.sitename;
              }
              if(self.appConfig.options.brand_icon){
                //CHANGE DOCUMENT FAVICON
                var link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                link.href = self.appConfig.options.base_url+self.appConfig.options.brand_icon;
              }
              
              self.$forceUpdate();
          });



      }

    if (window.localStorage.getItem('authenticated') === 'true') {
      this.$router.push('/dashboard');
    }
  },
};
</script>
<style>
    .auth-page{
        width: 100vw;
        min-height: 100vh;
        margin-bottom: 100px;
        background: url(../../assets/imagens/bg.jpg); 
        background-size: cover; 
        background-position:center;
        position: fixed;
        overflow: hidden;
        overflow-y: scroll;
        
    }
</style>