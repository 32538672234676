import CP from '@/pages/Company/Departments/Departments';
import View from '@/pages/Company/Departments/views/Department';
import Form from '@/pages/Company/Departments/views/DepartmentForm';
import List from '@/pages/Company/Departments/views/DepartmentsList';

export default [
    {
        path: 'empresa/departamentos',
        name: 'Departamentos',
        component: CP,
        meta: {
            requiresAuth: true
        },
        children:[
        {
            path: '',
            name: 'Departamentos',
            component: List,
            meta: {
            requiresAuth: true
            },
        },
        {
            path: 'novo',
            name: 'DepartamentoForm',
            component: Form,
            meta: {
            requiresAuth: true
            },
        },
        {
            path: ':id',
            name: 'DepartamentoView',
            component: View,
            meta: {
            requiresAuth: true
            },
        },
        {
            path: ':id/edit',
            name: 'DepartamentoForm',
            component: Form,
            meta: {
            requiresAuth: true
            },
        },
        
        ]
      }
]