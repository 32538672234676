<template>
    <div class="componente">
        <b-row style="width:50%; float: right; margin-top: -50px; margin-bottom: 40px;">
            <b-col class="text-right"> 
                
                    <button class="btn btn-primary" @click="$router.push({ path: '/componente/novo', params: {} })">  Adicionar </button>
                
            </b-col>
        </b-row>
        <b-row>ROUTER ::: <br><pre>{{r}}</pre></b-row>
        <b-row>
        <div class="row col-12  d-flex justify-content-between">
            <router-link to="/componente">Lista </router-link>
            <router-link to="/componente/1">Ver </router-link>
            <router-link to="/componente/1/edit">Editar </router-link>
            <router-link to="/componente/novo">Novo </router-link>
        </div>
        </b-row>

        <transition name="router-animation">
            <router-view />
        </transition>
    </div>
</template>
    
<script>
    export default {
    name: 'Componente',
    components: {},
    data() {
        return {
            r: {}
        };
    },
    computed: {

    },
    methods: {

    },
    
    beforeMount() {
    },
    mounted() {   
        this.$set(this,'r', this.$router);
    },
    created() {

    }
}
</script>
    