<template>
    <div class="cp">
        <b-row style="width:50%; float: right; margin-top: -50px; margin-bottom: 40px;">
            <b-col class="text-right"> 
                
                    <button class="btn btn-primary" @click="$router.push({ path: '/empresa/recursos-humanos/'+id+'/edit', params: {} })">  Editar Funcionário </button>
                    <button class="btn btn-danger ml-3" @click="eliminar()">  <i class="fa fa-trash"></i> </button>
                
            </b-col>
        </b-row>
        <!-- <br> Teste : {{teste}} -->


        <b-row class="col-12 m-0 p-0">
            <div class="col-9 row">
            <Widget>
                <div class="formgroup col-12 p-0 row">
                    <label class="col-4" for="mec_number">Nº Mecanográfico</label>
                    <div class="col-8">
                        {{dados.mec_number}}
                    </div>
                </div>

                <div class="formgroup col-12 p-0 row">
                    <label class="col-4" for="">Nome</label>
                    <div class="col-8">
                        {{dados.name}}
                    </div>
                </div>

                <div class="formgroup col-12 p-0 row">
                    <label class="col-4" for="">Email</label>
                    <div class="col-8">
                        {{dados.email}}
                    </div>
                </div>

                <div class="formgroup col-12 p-0 row" v-if="dados.telephone">
                    <label class="col-4" for="">Telefone</label>
                    <div class="col-8">
                        {{dados.telephone}}
                    </div>
                </div>

                <div class="formgroup col-12 p-0 row">
                    <label class="col-4" for="">Telemóvel</label>
                    <div class="col-8">
                        {{dados.mobilephone}}
                    </div>
                </div>

                <div class="formgroup col-12 p-0 row">
                    <label class="col-4" for="">Data Nascimento</label>
                    <div class="col-8">
                        {{dados.birthday}} <small style="font-size:10px;"> ({{moment().diff(dados.birthday, 'years')}} anos)</small>
                    </div>
                </div>

                <hr class="col-10 my-3">



                <div class="formgroup col-12 p-0 row">
                    <label class="col-4" for="">Departamento</label>
                    <div class="col-8">
                        {{dados.department}}
                    </div>
                </div>

                
                <div class="formgroup col-12 p-0 row">
                    <label class="col-4" for="">Categoria Profissional</label>
                    <div class="col-8">
                        {{dados.professional_category}}
                    </div>
                </div>

                <div class="formgroup col-12 p-0 row">
                    <label class="col-4" for="">Funcionário Desde</label>
                    <div class="col-8">
                        {{dados.employee_since}} <small style="font-size:10px;">({{moment().diff(dados.employee_since, 'years')}} anos)</small>
                    </div>
                </div>

            </Widget>
            </div>

            <div class="col-3 row m-0 p-0 justify-content-center ">
            <Widget class="text-center">
                <img src="@/assets/imagens/no_photo.jpeg" alt="no_photo" style="height: 150px"  v-if="!dados.profile_picture">
                <img :src="appConfig.options.base_url+dados.profile_picture"  style="height: 150px;" v-else>
            </Widget>
            </div>

        </b-row>

        <b-row>
        <b-col xs="12">
            <Widget>
            <div class=" panel p-0 m-0">
                <tabs>
                    <tab class="col" name="Certificados" :title="(dados.certificates ? dados.certificates.length : 0) +' Certificado(s)'" :selected="true">
                        <!-- <ContactLog :contact_log = 'dados.certificates'></ContactLog> -->
                        <!-- <pre>{{certificados}}</pre> -->

                        <vue-good-table  :columns="[{field: 'year', label: 'Ano' }, {field: 'field', label: 'Âmbito', 'sortable': false}, {field: 'title', label:'Formação', 'sortable': false}, {field:'no_hours', label:'Horas Certificadas'}, {field:'certificate_url', label:'Certificado', html: true,}]"
                                    :rows="certificados"
                                    :sort-options="{
                                        enabled: true,
                                        initialSortBy: {field: 'year', type: 'desc'}
                                    }"
                                    styleClass="col-12 m-o p-o vgt-table striped"
                        >
                        </vue-good-table>

                    </tab>
                    <!-- <tab class="col" name="Orçamentos" :title="(cs.quotes ? cs.quotes.length : 0) +' Orçamento(s)'">
                        <Quotes :quotes = 'cs.quotes'></Quotes>
                    </tab>
                    <tab class="col" name="Log" title="" v-if="$isAdmin() ">
                        <Log :cs='cs'> </Log>
                    </tab> -->
                </tabs>
            </div>
            </Widget>
        </b-col>
        </b-row>

<!--         
        <b-row class="col-12">
            <b-col>
                <pre>
                    {{dados}}
                </pre>
            </b-col>
        </b-row> -->
    </div>
</template>
    
<script>
    export default {
    name: 'Employee',
    props: ['teste'],
    components: {},
    data() {
        return {
            id: null,
            dados: {}
        };
    },
    computed: {
        certificados(){
            let certificados = [];
            
            if(Array.isArray(this.dados.certificates)){
                this.dados.certificates.forEach(e => {
                    let temp = e;
                    temp.year = this.moment(temp.date).format('YYYY');
                    if(temp.certificate_url != null) temp.certificate_url = '<a href="'+this.appConfig.options.base_url+temp.certificate_url+'" target="_blank"><i class="fa fa-graduation-cap"></i></a>'
                    certificados.push(temp);

                });
            }
            return certificados;
        }
    },
    methods: {
        eliminar(){
            let self = this;
            

            
                this.swal.fire({
                    title: 'De certeza que pretende eliminar o funcionário  '+ self.dados.name +'?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: `Eliminar`,
                    denyButtonText: `Cancelar`,
                    }).then((result) => {
                    if (result.isConfirmed) {
                        self.api.delete('company/employees/'+self.id).then(function(r){
                            if(r.success){ self.$router.push('/empresa/recursos-humanos');
                            }else{
                                // self.$router.push('/empresa/recursos-humanos/');
                            }
                        });


                    } else if (result.isDenied) {
                        //do Nothing.
                    }
                    })

            

        }
    },
    
    beforeMount() {
        
    },
    mounted() {   
    },
    created() {
        if(this.$route.params.id) {
            let self = this;
            self.id = this.$route.params.id;
            self.api.get('company/employees/'+self.id).then(function(r){
                console.log(r);
                if(r.success){
                    self.dados = r.dados;
                }else{
                    self.$router.push('/empresa/recursos-humanos');
                }
            });
        }
    }
}
</script>
    