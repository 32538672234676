<template>
    <div class="col-12 row">
        <Widget class="col-12 row m-0 px-0 ">
            <form class="col-12 row m-0 p-0 pt-5" @submit.prevent="save">
                <fieldset class="col-9 row ">
                    <div class="formgroup col-12 p-0 row">
                        <label class="col-4" for="department">Departamento</label>
                        <div class="col-8">
                            <input class="col-12" type="text" id="department" v-model="dados.department" required>
                            <p class="error" v-if="frm_log.error.department">Por favor preencha este campo</p>
                        </div>
                    </div>

                    <div class="formgroup col-12 p-0 mt-3 row">
                        <label class="col-4" for="">Morada Sede</label>
                        <div class="col-8">
                            <input type="text" class="col-12" v-model="dados.hq">
                            <span class="col-12 text-danger pt-1" v-if="frm_log.error.hq">Campo obrigatório</span>
                        </div>
                    </div>

                    <div class="formgroup col-12 p-0 mt-3 row">
                        <label class="col-4" for="">Responsável</label>
                        <div class="col-8">
                            <multiselect v-model="dados.manager_data" :options="employees_list" 
                                        :custom-label="Funcionários" placeholder="Seleccione um" label="name" track-by="name">
                                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong> </template>
                            </multiselect>
                            <span class="col-12 text-danger pt-1" v-if="frm_log.error.hq">Campo obrigatório</span>
                        </div>
                    </div>

                    

                </fieldset>

                <fieldset class="col-3 row m-0 p-0 justify-content-center ">
                    
                </fieldset>

                <div class="formgroup col-12 p-0 mt-5 pt-5 text-center">

                    <button class="px-5 btn border" type="submit" v-if="!saving">Guardar</button>
                    <span class="px-5 btn border" v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
                </div>
            </form>
        </Widget>
        <!-- <b-row class="col-12">
            <b-col>
                <pre>
                    {{dados}}
                </pre>
            </b-col>
        </b-row> -->
    </div>
    </template>
    
<script>
    import Multiselect from 'vue-multiselect'


    export default {
    name: 'DepartmentForm',
    components: {Multiselect},
    data() {
        return {
            frm_log: {},
            saving:false,
            id: null,
            dados: {},
            employees_list: {}
        };
    },
    watch: {
        
        'dados.manager_data'(manager){
            if(manager.id) this.$set(this.dados, 'manager_id', manager.id);

        }
    },
    computed: {

    },
    methods: {
        save(){
            let self = this;

            // self.$set(self, 'saving', true);
            
            self.$set(self.dados, 'active', 1);

            self.api.post('company/departments/save', self.dados).then(function(r){
                    console.log(r);
                    
                    if(r.success){
                        self.$router.push('/empresa/departamentos/'+r.dados.id);
                    }else{
                        self.frm_log.error = r.error;
                    }
                    self.frmLog(r.success, r.success ? 'Dados Atualizados com sucesso' : 'Ocorreu um erro no processamento do seu pedido');
                    self.$set(self, 'saving', false);
            });


        }
    },
    
    beforeMount() {
        
    },
    mounted() {   
    },
    created() {
        let self = this;

        this.frm_log.error = [];
        
        // this.frm_log.error.name = true;
        // this.frm_log.error.telephone = true;
        
        // this.$set(this.search,'isLoading', false);
        // this.$set(this.search,'list', []);
        // this.$set(this.search,'selected', null);


        if(this.$route.params.id) {
            
            self.id = this.$route.params.id;
            self.api.get('company/departments/'+self.id).then(function(r){
                console.log(r);
                if(r.success){
                    self.dados = r.dados;
                }else{
                    // self.$router.push('/empresa/departamentos');
                }
            });
        }


            self.api.get('company/employees/list').then(function(r){
                console.log(r);
                if(r.success){
                    self.employees_list = r.dados;
                }else{
                    // self.$router.push('/empresa/departamentos');
                }
            });
    }
}
</script>
    