<template>
    <div class="col-12 row">
        Single
    </div>
    </template>
    
<script>
    export default {
    name: 'Single',
    components: {},
    data() {
        return {
        };
    },
    computed: {

    },
    methods: {

    },
    
    beforeMount() {
    },
    mounted() {   
    },
    created() {

    }
}
</script>
    