<template>
  <b-list-group class="listGroup thin-scroll">
    
    <b-list-item class="listGroupItem py-2" :class="n.link.length > 0 ? 'link' : '' " v-for="(n, k) in notificationList" :key="k" @click="$emit('click', n)" style="cursor: pointer;" :style="(!n.is_seen) ? 'background: #DFD;' : ''">
        <span class="notificationIcon thumb-sm">
          <i class="fi flaticon-network fi-2x" />
        </span>
        <p class="text-ellipsis m-0">
          {{n.notification}}
          <time class="help-block m-0">
            {{n.date}}
          </time>
        </p>
    </b-list-item>

  </b-list-group>
</template>

<script>
export default {
  name: 'NotificationsList',
  props: ['notificationList']
};
</script>

<style src="./ListGroup.scss" lang="scss" />