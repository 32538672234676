var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-0 p-0"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"fixed-header":true,"xline-numbers":true,"sort-options":{
						enabled: true,
						initialSortBy: _vm.initialsort ? _vm.initialsort : {}
					},"select-options":{ 
						enabled: _vm.selectable,
						selectOnCheckboxOnly: true,
						selectionText: 'linhas seleccionadas',
						disableSelectInfo: true, 
					},"search-options":{
						enabled: _vm.searchable,
						placeholder: 'Pesquisar resultados',
					},"pagination-options":{
						enabled: true,
						
						perPage: 25,
						position: 'bottom',
						perPageDropdown: [10, 25, 50],
						dropdownAllowAll: true,
						//setCurrentPage: 1,
						nextLabel: 'Seguinte',
						prevLabel: 'Anterior',
						rowsPerPageLabel: 'Linhas por página',
						ofLabel: 'de',
						pageLabel: 'página', // for 'pages' mode
						allLabel: 'Todos',
						
					},"styleClass":"col-12 m-o p-o vgt-table striped"},on:{"on-selected-rows-change":_vm.selectionChanged,"on-row-click":_vm.onRowClick,"xon-row-dblclick":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label =='actions')?_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('i',{staticClass:"fa fa-cog"})]):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('button',{staticClass:"btn btn-danger py-0",attrs:{"role":"delete"},on:{"click":function($event){_vm.action = 'delete'}}},[_c('i',{staticClass:"fa fa-trash",attrs:{"color":"#900"}})])]):_c('span',{on:{"on-cell-click":_vm.onRowClick}},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[(_vm.hastableactions)?_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('i',{staticClass:"fa fa-table"})]):_vm._e(),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Sem resultados a apresentar ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }