<template>
    <div class="col-12 row">
        
        <form class="col-12 row m-0 p-0" @submit.prevent="save">

            <!-- DADOS PESSOAIS -->
            
            <fieldset class="col-12 row">
                <label class="col-12 border-bottom pb-3 text-left" for="">Registar utilizador Administrador</label>

                <div class="formgroup col-12 row">
                <label class="col-4" for="frmNome">Nome</label>
            
                <input id="frmNome" class="col" v-model="profile.name" type="text" required/>
                <div class="info col-12 d-flex justify-content-end">
                    <p class="col-8">
                    <small><em></em></small>
                    </p>
                </div>
                </div>


                <div class="formgroup col-12 row">
                <label class="col-4" for="frmDefaultPhone">Telefone</label>
                <input id="frmDefaultPhone" class="col" v-model="profile.telephone" type="text" placeholder="Telefone " required/>
                <div class="info col-12 d-flex justify-content-end">
                    <p class="col-8">
                    </p>
                </div>
                </div>


            </fieldset>
            <!-- FIM DADOS PESSOAIS -->

            <!-- DADOS CONTA -->          
            <fieldset class="col-12 row mt-5">
                <label class="col-12 border-bottom pb-3 text-left" for="">A sua Conta</label>

                <div class="formgroup col-12 row">
                <label class="col-4" for="frmEmail">Email</label>
                <input id="frmEmail" class="col-8" v-model.lazy="profile.email" type="email" placeholder="@" required/>
                <div class="info col-12 d-flex justify-content-end">
                    <p class="col-8">
                    </p>
                </div>
                </div>

                <div class="formgroup col-12 row">
                <label class="col-4" for="frmEmailConfirm">Confirme o Email</label>
                <input id="frmEmailConfirm" class="col-8" v-model.lazy="confirm_fields.email" type="email" placeholder="@" required/>
                <div class="info col-12 d-flex justify-content-end">
                    <p class="col-8"></p>
                    <p class="col-8 error" v-if="frmerror.email"><em>Os endereços introduzidos não coincidem.</em></p>
                    
                </div>
                </div>

                <div class="formgroup col-12 row">
                <label class="col-4" for="frmAuthPassword">Senha</label>
                <input id="frmAuthPassword" class="col-8" v-model.lazy="profile.pwd" type="password" placeholder="" required/>
                <div class="info col-12 d-flex justify-content-end">
                    <p class="col-8">
                    </p>
                </div>
                </div>

                <div class="formgroup col-12 row">
                <label class="col-4" for="frmAuthPasswordConfirm">Confirme a Senha</label>
                <input id="frmAuthPasswordConfirm" class="col-8" v-model.lazy="confirm_fields.pwd" type="password" placeholder="" required/>
                <div class="info col-12 d-flex justify-content-end">
                    <p class="col-8"></p>
                    <p class="col-8 error" v-if="frmerror.pwd"><em>As senhas introduzidas não coincidem.</em></p>
                    
                </div>
                </div>
            </fieldset>

            <fieldset class="col-12 text-center mt-5">
                <span class="btn border px-5 py-2 mx-5" @click="$emit('previous')" >Voltar</span>
                <button type="submit" class="btn border px-5 py-2 mx-5">Terminar Registo</button>
            </fieldset>
        </form>
        
    </div>
</template>
    
<script>
export default {
name: 'step02',
props: ['profile'],
components: {},
data() {
    return {
        frmerror:{},
        confirm_fields: {}

    };
},
computed: {
        errorMessage () {
        return null
        },
        phone_prefix(){
        return this.countries.slice(0).sort((a, b) => (a.Dial > b.Dial) ? 1 : -1);
    }
},

watch: {
    'profile.email': function (){
        this.validateConfirmFields();
    },
    'profile.pwd': function (){
        this.validateConfirmFields();
    },
    confirm_fields: {
        deep: true,
        handler() {this.validateConfirmFields()}
    }
},
methods: {
    search(){
        
    },
    validateConfirmFields(){
            let self = this;
            // console.log('entrei na validação');
            Object.keys(this.confirm_fields).forEach(k => {
                let v = self.confirm_fields[k];

                if(v.length > 0){
                    if(v != self.profile[k]){
                        // console.log(v+' != '+self.profile[k]);
                        self.frmerror[k]= true;
                    }else{
                        // console.log(v+' == '+self.profile[k]);
                        if(self.frmerror[k]) { self.$delete(self.frmerror, k);}
                    }
                }else{
                    // console.log(v+' == '+self.profile[k]);
                    self.frmerror[k]= false;
                    if(self.frmerror[k]) { self.$delete(self.frmerror, k); }
                }
            })

        },
    save() {
        //alert(type);
        let self = this;
        
        // this.api.post('users/register', this.profile).then(function(response){
        this.api.post('sys/config/register_su', this.profile).then(function(response){
            if(response.success){
                self.$emit('next');
            }else{
                self.frmerror  = response.error;
            }
            
        });
        
    }
},
created() {
},
mounted() {    
}
};
</script>
    