import Component from './Component';

import CoursesList from './views/courses_list';
import CoursesForm from './views/courses_form';
import CoursesSingle from './views/courses_single';


import AgendaList from './views/agenda_list';
import AgendaForm from './views/agenda_form';
import AgendaSingle from './views/agenda_single';

export default [
  {
    path: 'tad',
    alias: ['formacao'],
    name: 'Componente',
    component: Component,
    meta: {
      requiresAuth: true
    },
    children:[
      {
        path: '',
        name: 'List',
        component: AgendaList,
        meta: {
          requiresAuth: true
        },
      },
      {
      path: 'cursos',
      name: 'Courses',
      component: CoursesList,
      },
      {
        path: 'cursos/novo',
        name: 'NewCourse',
        component: CoursesForm,
      },
      {
        path: 'cursos/:id',
        name: 'Course',
        component: CoursesSingle,
      },
      {
        path: 'cursos/:id/edit',
        name: 'CourseEdit',
        component: CoursesForm,
      },
      {
        path: 'cursos-agendados',
        name: 'New',
        component: AgendaList,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'cursos-agendados/novo',
        name: 'New',
        component: AgendaForm,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'cursos-agendados/:id',
        name: 'View',
        component: AgendaSingle,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'cursos-agendados/:id/edit',
        name: 'Edit',
        component: AgendaForm,
        meta: {
          requiresAuth: true
        },
      }
      
    ]
  },
]